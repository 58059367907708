import { appPaths } from '@/configs';
import { useGetProfile, useLogout } from '@/services';
import { EphemeralStorage, PersistentStorage } from '@/utils';
import { Avatar, Box, Button, Divider, Popover, Surface, Text } from '@axazara/raiton-atoms';
import { ArrowDownFill, LoginOutline } from '@axazara/raiton-icons';
import { useNavigate } from 'react-router-dom';

export function UserCard() {
  const { data: staff } = useGetProfile();
  const logoutMutation = useLogout();
  const navigate = useNavigate();

  const onLogout = async () => {
    await logoutMutation.mutateAsync(undefined);
    PersistentStorage.clear();
    EphemeralStorage.clear();
    navigate(appPaths.login.path);
  };

  return (
    <Box>
      <Popover position="bottom-end" withinPortal>
        <Popover.Target>
          <Box className="flex flex-row items-center space-x-2">
            <Avatar size={48} src={staff?.profile_photo_url} />
            <ArrowDownFill className="w-6 h-6" />
          </Box>
        </Popover.Target>
        <Popover.Dropdown className="border-none -mt-4 bg-transparent">
          <Surface radius={8} shadow="lg" className="p-4">
            <Text className="mb-2 text-p-02-semibold">{`${staff?.first_name} ${staff?.last_name}`}</Text>
            <Divider className="border-neutral-80 mb-2" />

            <Button
              fullWidth
              size="xs"
              variant="filled"
              loading={logoutMutation.isLoading}
              onClick={onLogout}
              color="error.3"
              leftIcon={<LoginOutline className="w-6 h-6" />}
              className="text-p-01-medium mt-4"
            >
              Logout
            </Button>
          </Surface>
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
}
