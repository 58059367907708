import { Box, InputField, Text, Select, Button, Avatar, ActionIcon } from '@axazara/raiton-atoms';
import { GalleryAddOutline, ArrowCircleLeftOutline, GalleryOutline } from '@axazara/raiton-icons';
import { Dropzone, showNotification, useForm } from '@axazara/raiton-molecules';
import { CreativePayload } from 'utilities';
import { useAddCreative, useGetAllSocialMedia } from '@/features';
import { useNavigate } from 'react-router-dom';
import { useSetPageMeta } from '@/hooks';
import { useGetApps } from '@/services';
import React from 'react';

export function AddImageCreative() {
  useSetPageMeta({
    title: 'Creatives',
    icon: GalleryOutline,
  });

  const { data: apps } = useGetApps({});

  const navigate = useNavigate();

  const languageData = [
    { label: 'EN', value: 'en' },
    { label: 'FR', value: 'fr' },
  ];
  const creativesType = [{ label: 'Image', value: 'image', color: 'bg-secondary-50' }];
  const addCreativesForm = useForm<Partial<CreativePayload>>({
    initialValues: {
      title: '',
      language: '',
      app_id: '',
      social_media: '',
      image: '',
      type: 'image',
    },
  });
  const { data: mediaData } = useGetAllSocialMedia();
  const useAddCreativeMutation = useAddCreative();

  const handleSubmit = async (values: any) => {
    const payload = new FormData();
    payload.append('title', values.title);
    payload.append('language', values.language);
    payload.append('app_id', values.app_id);
    payload.append('social_media', values.social_media);
    payload.append('type', values.type);
    payload.append('image', values.image);

    try {
      const response = await useAddCreativeMutation.mutateAsync(payload);
      addCreativesForm.reset();
      showNotification({
        message: (response as any)?.message,
        color: 'success',
        autoClose: 5000,
      });
    } catch (error) {
      showNotification({
        message: (error as any)?.message,
        color: 'error',
      });
    }
  };
  return (
    <Box className="pt-4 pb-12">
      <Box className="ml-10 pb-5">
        <ActionIcon radius={100} onClick={() => navigate(-1)}>
          <ArrowCircleLeftOutline className="w-6 h-6" />
        </ActionIcon>
      </Box>
      <Box className="px-8 space-y-4 max-w-lg mx-auto">
        <Text className="text-heading-04-sm-bold text-center">Add image</Text>
        <Box component="form" onSubmit={addCreativesForm.onSubmit((values) => handleSubmit(values))}>
          <InputField
            {...addCreativesForm.getInputProps('title')}
            label="Creatives name"
            classNames={{
              input: 'rounded-lg',
              label: 'mb-3 text-p-01-semibold text-neutral-40',
            }}
            radius={12}
            placeholder="Creatives name"
            size="xs"
            className="w-full mb-5"
          />
          {apps && (
            <Select
              {...addCreativesForm.getInputProps('app_id')}
              placeholder="App"
              label="App"
              classNames={{
                input: 'rounded-lg',
                label: 'mb-3 text-p-01-semibold text-neutral-40',
              }}
              data={apps.data as any}
              optionResolver={(app: any) => ({ label: app.name, value: app.id, icon_url: app.icon_url })}
              type="text"
              size="xs"
              className="mb-5"
              radius={12}
              itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
                <Box className="py-2" ref={ref} key={value} {...others}>
                  <Box className="flex flex-row items-center space-x-2">
                    <Avatar size={32} src={others.icon_url} />
                    <Text className="text-p-01-medium">{label}</Text>
                  </Box>
                </Box>
              ))}
              styles={(theme) => ({
                input: {
                  borderColor: theme.colors.neutral[6],
                },
              })}
            />
          )}

          <Select
            {...addCreativesForm.getInputProps('language')}
            placeholder="language"
            label="Language"
            classNames={{
              input: 'rounded-lg',
              label: 'mb-3 text-p-01-semibold text-neutral-40',
            }}
            data={languageData as any}
            optionResolver={(language: any) => ({ label: language.label, value: language.value })}
            type="text"
            size="xs"
            className="mb-5"
            radius={12}
            itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
              <Box className="py-2" ref={ref} key={value} {...others}>
                <Box className="flex flex-row items-center ">
                  <Text className="text-p-01-medium">{label}</Text>
                </Box>
              </Box>
            ))}
            styles={(theme) => ({
              input: {
                borderColor: theme.colors.neutral[6],
              },
            })}
          />
          {mediaData && (
            <Select
              {...addCreativesForm.getInputProps('social_media')}
              placeholder="media"
              label="Social media"
              classNames={{
                input: 'rounded-lg',
                label: 'mb-3 text-p-01-semibold text-neutral-40',
              }}
              data={mediaData as any}
              optionResolver={(media: any) => ({ label: media.label, value: media.value, icon_url: media.icon_url })}
              type="text"
              size="xs"
              className="mb-5"
              radius={12}
              itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
                <Box className="py-2" ref={ref} key={value} {...others}>
                  <Box className="flex flex-row items-center space-x-2 ">
                    <Avatar size={32} src={others.icon_url} />
                    <Text className="text-p-01-medium">{label}</Text>
                  </Box>
                </Box>
              ))}
              styles={(theme) => ({
                input: {
                  borderColor: theme.colors.neutral[6],
                },
              })}
            />
          )}

          <Dropzone
            accept={['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg']}
            {...addCreativesForm.getInputProps('image')}
            maxFiles={1}
            maxSize={2 * 1024 ** 2}
            onDrop={(files) => {
              addCreativesForm.setFieldValue('image', files[0] as any);
            }}
            className={`${addCreativesForm.values.image ? 'bg-primary' : 'bg-neutral'}  py-8`}
          >
            <Box className="flex flex-col items-center justify-center">
              <GalleryAddOutline className="w-12 h-12 text-neutral-60 mx-auto" />
              {addCreativesForm.values.image ? (
                <Text>{addCreativesForm.values.title}</Text>
              ) : (
                <>
                  <Box component="p">
                    <Text className="text-primary-50" component="span">
                      Upload a file or drag here
                    </Text>
                  </Box>
                  <Text color="dimmed"> jpeg,png, jpg, svg or gif</Text>
                </>
              )}
            </Box>
          </Dropzone>
          <Select
            {...addCreativesForm.getInputProps('type')}
            placeholder="Type"
            data={creativesType as any}
            optionResolver={(type: any) => ({ label: type.label, value: type.value })}
            classNames={{ label: 'text-neutral-50 mb-2 text-p-02' }}
            type="text"
            size="xs"
            className="mb-5 hidden"
            radius={12}
            itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
              <Box className="py-2" ref={ref} key={value} {...others}>
                <Box className="flex flex-row items-center space-x-2 ">
                  <Text className="text-p-01-medium">{label}</Text>
                </Box>
              </Box>
            ))}
            styles={(theme) => ({
              input: {
                borderColor: theme.colors.neutral[6],
              },
            })}
          />
          <Box className="text-center pt-6">
            <Button
              type="submit"
              loading={useAddCreativeMutation.isLoading}
              disabled={!addCreativesForm.isDirty()}
              size="xs"
            >
              Add image
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
