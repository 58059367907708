import React from 'react';
import { Box, InputField, DatePickerInput, Select } from '@axazara/raiton-atoms';
import { SearchNormal1Outline, CloseCircleFill, Calendar2Outline, ArrowDownOutline } from '@axazara/raiton-icons';
import { useTranslation } from 'react-i18next';
import { StatusFilterMenu, StatusType } from './StatusFilterMenu';
import { Currency, Apps, App } from 'utilities';
import { AppFilterMenu } from './AppFilterMenu';

type TransactionsFilterProps = {
  // Search related fields
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  debouncedSearch: string;
  searchAppQuery: string;
  setSearchAppQuery: React.Dispatch<React.SetStateAction<string>>;

  // Status filter fields
  selectedStatus: StatusType | null;
  setSelectedStatus: React.Dispatch<React.SetStateAction<StatusType | null>>;
  statusData: StatusType[];
  openedStatusFilter: boolean;
  setOpenedStatusFilter: React.Dispatch<React.SetStateAction<boolean>>;

  // Date range filter fields
  selectedDateRange: [Date | null, Date | null];
  setSelectedDateRange: React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>;

  // App filter fields
  apps: Apps | undefined;
  selectedApp: App | null;
  setSelectedApp: React.Dispatch<React.SetStateAction<App | null>>;
  openedAppFilter: boolean;
  setOpenedAppFilter: React.Dispatch<React.SetStateAction<boolean>>;

  currencies: Currency[] | undefined;
  selectedCurrency: string | null;
  setSelectedCurrency: React.Dispatch<React.SetStateAction<string | null>>;

  // URL parameter handling
  setUrlParams: any;
};

export const TransactionsFilter = ({
  searchQuery,
  setSearchQuery,
  selectedStatus,
  setSelectedStatus,
  selectedDateRange,
  setSelectedDateRange,
  statusData,
  apps,
  openedStatusFilter,
  setOpenedStatusFilter,
  selectedApp,
  setSelectedApp,
  searchAppQuery,
  setSearchAppQuery,
  openedAppFilter,
  setOpenedAppFilter,

  setUrlParams,
  debouncedSearch,

  currencies,
  selectedCurrency,
  setSelectedCurrency,
}: TransactionsFilterProps) => {
  const { t } = useTranslation();

  return (
    <Box className="flex gap-2 w-full">
      {/* Search Input */}
      <InputField
        radius={12}
        placeholder={`${t('search_transaction')}`}
        size="xs"
        icon={<SearchNormal1Outline className="w-5 h-5" />}
        onChange={({ target }) => {
          setSearchQuery(target.value);
          setUrlParams({ search: target.value });
        }}
        className="w-full"
        value={searchQuery ?? ''}
        rightSection={
          <Box>
            {debouncedSearch ? (
              <CloseCircleFill
                className="w-4 h-4 text-neutral-40"
                onClick={() => {
                  setSearchQuery('');
                  setUrlParams({ search: '' });
                }}
              />
            ) : null}
          </Box>
        }
      />

      <Box className="flex flex-row gap-2">
        <StatusFilterMenu
          statusData={statusData}
          selectedStatus={selectedStatus}
          openedStatusFilter={openedStatusFilter}
          setSelectedStatus={setSelectedStatus}
          setOpenedStatusFilter={setOpenedStatusFilter}
        />
        {currencies && (
          <Select
            placeholder={`${t('filter_by_currency')}`}
            data={currencies as any}
            optionResolver={(currency: any) => ({ label: currency.name, value: currency.code })}
            value={selectedCurrency}
            onChange={(value) => {
              setSelectedCurrency(value ?? '');
              setUrlParams({ currency: value ?? '' });
            }}
            searchable
            clearable
            size="xs"
            className="w-40"
            styles={(theme) => ({
              input: {
                borderColor: theme.colors.neutral[6],
                '::placeholder': {
                  color: theme.colors.neutral[3],
                },
              },
              rightSection: {
                color: theme.colors.neutral[3],
              },
              item: {
                '&[data-selected]': {
                  backgroundColor: theme.colors.neutral[9],
                  color: theme.black,
                },
              },
              dropdown: {
                borderColor: theme.colors.neutral[7],
              },
            })}
          />
        )}

        <AppFilterMenu
          apps={apps}
          selectedApp={selectedApp}
          setSelectedApp={setSelectedApp}
          searchAppQuery={searchAppQuery}
          setSearchAppQuery={setSearchAppQuery}
          openedAppFilter={openedAppFilter}
          setOpenedAppFilter={setOpenedAppFilter}
        />

        {/* Date Range Picker */}
        <DatePickerInput
          size="xs"
          type="range"
          clearable
          icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
          // @ts-ignore
          placeholder={`${t('filter_by_date')}`}
          value={selectedDateRange}
          numberOfColumns={2}
          styles={(theme) => ({
            root: {
              '> div': {
                borderColor: theme.colors.neutral[8],
              },
            },
          })}
          onChange={setSelectedDateRange}
          allowSingleDateSelectionInRange
          rightSection={selectedDateRange[1] ? null : <ArrowDownOutline className="w-4 h-4 text-neutral-40" />}
        />
      </Box>
    </Box>
  );
};

export * from './StatusFilterMenu';
