export default {
  dashboard: 'Tableau de bord',
  payments: 'Paiements',
  payouts: 'Transferts',
  customers: 'Clients',
  'layout.sidebar.statistique': 'Statistique',
  settings: 'Paramètres',
  Team: 'Équipe',
  developers: 'Développeurs',
  sandbox: 'Mode bac à sable',
  enable_app: 'Activez votre application',
  enable_app_description:
    'Votre application n’est pas encore activée. Activez votre application pour la mettre en ligne et recevoir les paiements de vos clients.',
  enable_the_app: "Activer l'application",
  transactions: 'Transactions',
  gateways: 'Passerelles',
  methods: 'Méthodes',
  documentation: 'Documentation',
  helpCenter: "Centre d'aide",
  tutorials: 'Tutoriels',
  collapse_sidebar: 'Réduire le menu',
};
