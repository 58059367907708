import { Box, Text, Divider, ActionIcon, Button, Surface, Avatar } from '@axazara/raiton-atoms';
import { ArrowCircleLeftOutline, SearchNormal1Outline, MoneyReciveOutline } from '@axazara/raiton-icons';
import { openModal } from '@axazara/raiton-molecules';
import { formatDate } from 'utilities';
import { useSetPageMeta } from '@/hooks';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  CopyableInformationItem,
  EarningStatusBadge,
  EarningsMetadata,
  RejectEarningsTransactions,
  useGetEarningDetails,
} from '@/features';
import { appPaths } from '@/configs';

function EarningDetailsPageRenderer({ id }: { id: string }) {
  const navigate = useNavigate();

  const { data: earning, isError } = useGetEarningDetails({ id });

  if (isError) return <Navigate to={appPaths.earnings.path} />;

  return (
    <Box className="pt-4">
      <Box className="px-10">
        <Box className="flex justify-between items-center">
          <Box className="flex space-x-4">
            <ActionIcon radius={100} onClick={() => navigate(-1)}>
              <ArrowCircleLeftOutline className="w-6 h-6" />
            </ActionIcon>
            <Box className="flex space-x-4 mb-4">
              <Text className="text-heading-05-bold">{earning?.amount_formatted}</Text>
              <EarningStatusBadge status={earning?.status} />
            </Box>
          </Box>
          <Box className="flex gap-3 mb-6">
            {earning?.status === 'pending' && (
              <Button
                color="error.3"
                size="xs"
                onClick={() =>
                  openModal({
                    padding: 32,
                    size: 'lg',
                    centered: true,
                    title: <Text className=" text-neutral-30 text-heading-05-bold">Rejected transaction</Text>,
                    children: <RejectEarningsTransactions earning={earning} />,
                  })
                }
              >
                Rejected transaction
              </Button>
            )}
          </Box>
        </Box>
        <Divider color="neutral.6" className="mb-8" />
      </Box>
      <Box className="flex justify-between gap-x-5 mb-16">
        <Box className="w-full">
          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">User</Text>
            <Box className="flex justify-between gap-10">
              <Box className="flex items-center space-x-4">
                <Avatar src={earning?.user?.profile_photo_url} size={48} />
                <Box>
                  <Text className="text-p-01-medium mb-2">{earning?.user?.first_name}</Text>
                  <Text className="text-caption-medium text-neutral-40">{earning?.user?.email}</Text>
                </Box>
              </Box>
              <Box>
                <Button
                  component={Link}
                  to={`${appPaths.users.path}/${earning?.user?.id}`}
                  size="xs"
                  variant="outline"
                  rightIcon={<SearchNormal1Outline className="w-5 h-5" />}
                >
                  View
                </Button>
              </Box>
            </Box>
          </Surface>
          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <Box className="flex flex-row justify-between mb-4">
              <Text className="text-p-01-semibold md:w-52 text-neutral-20">Date and hour</Text>
              <Text className="text-neutral-50 text-p-01">
                {earning?.created_at && formatDate(earning?.created_at, 'en', 'D MMM YYYY, HH:mm')}
              </Text>
            </Box>
            <Box className="flex justify-between mb-4">
              <Text className="text-p-01-semibold md:w-52 text-neutral-20">Date de paiement</Text>
              <Text className="text-p-01 text-neutral-50">
                {earning?.due_at ? formatDate(earning?.due_at, 'en', 'D MMM YYYY') : 'N/A'}
              </Text>
            </Box>
            <Box className="mb-4 flex justify-between">
              <Text className="text-p-01-semibold text-neutral-20">Status</Text>
              <EarningStatusBadge status={earning?.status} />
            </Box>
            <Box className="mb-4 flex justify-between">
              <Text className="text-p-01-semibold md:w-52 text-neutral-20">Amount</Text>
              <Text className="text-p-01 text-neutral-50">{earning?.amount_formatted}</Text>
            </Box>
            <Box className="flex justify-between">
              <Text className="text-p-01-semibold md:w-52 text-neutral-20">Apps</Text>
              <Box className="flex items-center space-x-2">
                <Avatar src={earning?.app?.icon_url} size={32} />
                <Text className="text-p-01 text-neutral-50">{earning?.app?.name}</Text>
              </Box>
            </Box>
          </Surface>
          {earning?.status === 'revoked' && (
            <Box>
              <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
                <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">Rejected by</Text>
                <Box className="flex justify-between gap-10">
                  <Box className="flex items-center space-x-4">
                    <Avatar src={earning.revoked_by_staff.profile_photo_url} size={48} />
                    <Box>
                      <Text className="text-p-01-medium mb-2">
                        {earning.revoked_by_staff.first_name} {earning.revoked_by_staff.last_name}
                      </Text>
                      <Text className="text-caption-medium text-neutral-40">{earning.revoked_by_staff.email}</Text>
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      component={Link}
                      to={`${appPaths.staffs.path}/${earning.revoked_by_staff.id}`}
                      size="xs"
                      variant="outline"
                      rightIcon={<SearchNormal1Outline className="w-5 h-5" />}
                    >
                      View
                    </Button>
                  </Box>
                </Box>
              </Surface>
              <Surface radius={18} className="p-4 border border-neutral-80">
                <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">Rejected motif</Text>
                <Text className="text-p-01 text-neutral-40">{earning.revocation_reason} </Text>
              </Surface>
            </Box>
          )}
        </Box>
        <Box className="w-full">
          {earning?.id && <CopyableInformationItem label="Reference" value={earning?.id} />}

          {earning?.referral_id && <CopyableInformationItem label="Referral id" value={earning?.referral_id} />}
          {earning?.order_id && <CopyableInformationItem label="Order id" value={earning?.order_id} />}

          {earning?.metadata && <EarningsMetadata metadata={earning?.metadata} />}
        </Box>
      </Box>
    </Box>
  );
}

export function EarningDetails() {
  useSetPageMeta({
    title: 'Earnings',
    icon: MoneyReciveOutline,
  });

  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.earnings.path} />;
  }

  return <EarningDetailsPageRenderer id={id as string} />;
}
