import { Box, Text, Surface, Divider, Button, Tooltip, Avatar } from '@axazara/raiton-atoms';
import { Profile2userOutline, MouseCircleOutline, CopySuccessOutline, CopyOutline } from '@axazara/raiton-icons';
import { ReferralLink } from 'utilities';
import { useClipboard } from '@axazara/raiton-utils';

export function LinkInfos({ link }: { link: ReferralLink }) {
  const { copy, copied } = useClipboard({ timeout: 500 });
  return (
    <Box className="space-y-8 my-8">
      <Box className="flex space-x-2 mb-8 justify-center items-center">
        <Tooltip label={<Box>{link?.app?.name}</Box>}>
          <Avatar src={link?.app?.icon_url} size={32} />
        </Tooltip>
        <Box>
          <Text className="text-p-02">{link?.app?.name}</Text>
        </Box>
      </Box>
      <Box className="flex gap-6 mb-8">
        <Surface radius={16} className="bg-success w-full p-6 grid place-items-center">
          <Box className="bg-neutral-10 mb-2 rounded-full w-11 h-11 flex justify-center items-center">
            <MouseCircleOutline className="w-5 h-5 text-white" />
          </Box>
          <Text className="text-p-01-medium mb-2">Total Clicks</Text>
          <Text component="span" className="text-heading-05-bold">
            {link?.stats?.clicks}
          </Text>
        </Surface>
        <Surface radius={16} className="bg-secondary w-full pb-6 grid place-items-center">
          <Box className="bg-neutral-10 rounded-full w-11 h-11 flex justify-center items-center mb-2">
            <Profile2userOutline className="w-5 h-5 text-white" />
          </Box>
          <Text className="text-p-01-medium mb-2">Total Referals</Text>
          <Text component="span" className="text-heading-05-bold">
            {link?.stats?.referrals}
          </Text>
        </Surface>
      </Box>
      <Divider color="neutral.9" className="border-neutral-90 mb-8" />
      <Text className="text-center text-heading-04-bold">Share your link</Text>
      <Box className="bg-neutral rounded-xl p-3">
        <Text className="text-p-02-medium flex justify-center">{link?.link}</Text>
      </Box>
      <Button
        type="submit"
        onClick={() => copy(link?.link)}
        leftIcon={
          copied ? (
            <CopySuccessOutline className="w-4 h-4 text-white ml-2" />
          ) : (
            <CopyOutline className="w-4 h-4 text-white" />
          )
        }
        fullWidth
      >
        {copied ? 'copied' : 'copy'}
      </Button>
    </Box>
  );
}
