import { Box, Button, Text } from '@axazara/raiton-atoms';
import { useClipboard } from '@axazara/raiton-utils';
import { Creative } from 'utilities';

export function CreativeTextItem({ creative }: { creative: Creative }) {
  const { copied: creativesContentCopied, copy: copyCreativesContent } = useClipboard({ timeout: 500 });

  return (
    <Box className="space-y-4">
      <Box key={creative.id}>
        <Box className="flex flex-col justify-between gap-4 border rounded-3xl p-4 border-neutral-90">
          <Box className="flex flex-col gap-3 md:mb-2">
            <Box className="bg-primary rounded-3xl w-full p-4 h-48 overflow-hidden">
              <Text className="text-p-01 line-clamp-6">{creative.content}</Text>
            </Box>
          </Box>
          <Button size="xs" onClick={() => copyCreativesContent(creative.content)} variant="outline">
            {creativesContentCopied ? 'copied' : 'copy'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
