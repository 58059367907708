import { EarningsTable, EmptyState, PaginationControls, TransactionsFilter } from '@/components';

import { Box, LoadingOverlay } from '@axazara/raiton-atoms';

import { useUserEarnings } from './useUserEarnings';
import { useGetUserEarnings } from '@/features';

export function EarningsTabRenderer({ id }: { id: string }) {
  const {
    // Data and loading states
    earnings,
    isLoading,
    apps,

    // Search states and setters
    setSearchQuery,
    setSearchAppQuery,

    debouncedSearch,
    searchQuery,
    searchAppQuery,

    // Filter states and setters
    setSelectedStatus,
    setSelectedApp,
    setSelectedDateRange,

    // Dropdown open states and setters
    setOpenedStatusFilter,
    setOpenedAppFilter,

    // Pagination
    page,
    setPage,

    // URL Params
    setUrlParams,

    // Static and selected filter states
    statusData,
    selectedApp,
    openedStatusFilter,
    openedAppFilter,
    selectedStatus,
    selectedDateRange,

    currencies,
    selectedCurrency,
    setSelectedCurrency,
  } = useUserEarnings({ id });

  return (
    <Box className="py-5">
      <LoadingOverlay visible={isLoading} loaderProps={{ color: 'secondary' }} />
      <Box>
        <Box className="mb-4">
          <TransactionsFilter
            currencies={currencies}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            debouncedSearch={debouncedSearch}
            searchAppQuery={searchAppQuery}
            setSearchAppQuery={setSearchAppQuery}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            statusData={statusData}
            openedStatusFilter={openedStatusFilter}
            setOpenedStatusFilter={setOpenedStatusFilter}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            apps={apps?.data}
            selectedApp={selectedApp}
            setSelectedApp={setSelectedApp}
            openedAppFilter={openedAppFilter}
            setOpenedAppFilter={setOpenedAppFilter}
            setUrlParams={setUrlParams}
          />
        </Box>
        <Box className="relative">
          <LoadingOverlay visible={isLoading} loaderProps={{ color: 'secondary' }} />
          {earnings?.data && (
            <>
              <EarningsTable earnings={earnings.data} />
              <PaginationControls page={page} pagination={earnings?.pagination} onPageChange={setPage} />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export function EarningsTab({ id }: { id: string }) {
  const { data: earnings, isLoading } = useGetUserEarnings(id, { page: 1 });

  if (isLoading) return <LoadingOverlay visible />;

  if (earnings?.data && earnings.data.length > 0) return <EarningsTabRenderer id={id} />;

  return <EmptyState title="No earnings yet" />;
}
