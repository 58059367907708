import { showNotification } from '@axazara/raiton-molecules';
import { TickCircleFill, CloseCircleOutline } from '@axazara/raiton-icons';

export function showSuccessNotification(message: string) {
  return showNotification({
    message,
    icon: <TickCircleFill className="w-6 h-6 text-success-40" />,
    autoClose: 3000,
    color: 'success',
  });
}

export function showErrorNotification(message: string) {
  return showNotification({
    message,
    icon: <CloseCircleOutline className="w-5 h-5 text-critical-40" />,
    autoClose: 3000,
    color: 'error',
  });
}
