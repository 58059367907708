import { Box, Button, Text } from '@axazara/raiton-atoms';
import { useNavigate } from 'react-router-dom';

export function NotFound() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Box className="w-full h-screen grid place-content-center">
      <Text className="text-display-05 mb-5 text-neutral-20">Page not Found</Text>
      <Button onClick={goBack} className="w-auto">
        Go Back
      </Button>
    </Box>
  );
}
