import { Box, Tabs } from '@axazara/raiton-atoms';
import { TaskSquareFill, Edit2Fill, GalleryOutline } from '@axazara/raiton-icons';
import EditCreativeTab from '../components/EditCreativeTab';
import CreativeInformationsTab from '../components/CreativeInformationsTab';
import { useGetCreativesDetails } from '../services';
import { Navigate, useParams } from 'react-router-dom';
import { appPaths } from '@/configs';
import { useSetPageMeta } from '@/hooks';
import { useGetApps } from '@/services';

function CreativeDetailsPageRenderer({ id }: { id: string }) {
  const { data: creative, isError } = useGetCreativesDetails({ id });

  const { data: apps } = useGetApps({});

  if (isError) return <Navigate to={appPaths.creatives.path} />;
  return (
    <Box className="pt-4">
      <Tabs defaultValue="information">
        <Box className="bg-white w-full">
          <Tabs.List className="w-80 mx-auto flex justify-between text-p-01-semibold mb-4">
            <Tabs.Tab icon={<TaskSquareFill className="w-5 h-5" />} value="information">
              Informations
            </Tabs.Tab>
            <Tabs.Tab icon={<Edit2Fill className="w-5 h-5" />} value="edit_resource">
              Edit Resource
            </Tabs.Tab>
          </Tabs.List>
        </Box>
        <Tabs.Panel value="information">{creative && <CreativeInformationsTab creative={creative} />}</Tabs.Panel>
        <Tabs.Panel value="edit_resource">
          {creative && apps && <EditCreativeTab creative={creative} apps={apps} />}
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
}

export function CreativeDetails() {
  useSetPageMeta({
    title: 'Creatives',
    icon: GalleryOutline,
  });

  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.creatives.path} />;
  }

  return <CreativeDetailsPageRenderer id={id} />;
}
