import { Box, Table, Text, Avatar, ActionIcon, InputField, LoadingOverlay } from '@axazara/raiton-atoms';
import { SearchStatusOutline, Profile2userFill, SearchNormal1Outline, CloseCircleFill } from '@axazara/raiton-icons';
import { Link } from 'react-router-dom';
import { useGetUsers } from '@/features';
import { PaginatedUsers, formatDate } from 'utilities';
import { EmptyState } from '@/components';
import { useSetPageMeta } from '@/hooks';
import { useUsers } from './useUsers';
import { PaginationControls } from '@/components/PaginationControls';

function UsersRendererPage({ initialData }: { initialData: PaginatedUsers }) {
  const { isLoading, searchQuery, setSearchQuery, debouncedSearch, users, page, setPage } = useUsers({
    initialData,
  });

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="pt-4">
        <Box className="flex w-full mb-6">
          <InputField
            placeholder="search"
            size="xs"
            icon={<SearchNormal1Outline className="w-5 h-5" />}
            className="w-full"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            rightSection={
              debouncedSearch ? (
                <CloseCircleFill
                  className="w-4 h-4 text-neutral-60"
                  onClick={() => {
                    setSearchQuery('');
                  }}
                />
              ) : null
            }
            classNames={{
              input: 'rounded-xl',
            }}
          />
        </Box>
        <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
          <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
            <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral font-poppins">
              <Table.HeadCell>ID</Table.HeadCell>
              <Table.HeadCell>Customer</Table.HeadCell>
              <Table.HeadCell>Create at</Table.HeadCell>
              <Table.HeadCell className="flex justify-end">View</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y divide-neutral-80">
              {users?.data.length &&
                users.data.map((user) => (
                  <Table.Row key={user.id} className="bg-white">
                    <Table.Cell>
                      <Text className="text-p-01-medium">{user.id}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Box className="md:flex-row flex flex-col items-center space-x-4">
                        <Avatar src={user.profile_photo_url} size={48} />
                        <Box>
                          <Text className="text-p-01-semibold text-neutral-20">
                            {user.first_name} {user.last_name}
                          </Text>
                          <Text className="text-caption-medium text-neutral-60">{user.email}</Text>
                        </Box>
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <Text className="text-p-01 text-neutral-40">
                        {formatDate(user.created_at, 'en', 'D MMM YYYY, HH:mm')}
                      </Text>
                    </Table.Cell>

                    <Table.Cell>
                      <Box component={Link} to={`/users/${user.id}`} className="cursor-pointer flex justify-end">
                        <ActionIcon color="primary" className="p-0.5" size="xl" radius={100}>
                          <SearchStatusOutline className="w-6 h-6 text-primary-50" />
                        </ActionIcon>
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Box>
        <Box>
          {users?.data && <PaginationControls page={page} pagination={users?.pagination} onPageChange={setPage} />}
        </Box>
      </Box>
    </Box>
  );
}
export function Users() {
  useSetPageMeta({
    title: 'Users',
    icon: Profile2userFill,
  });
  const { data: users, isLoading } = useGetUsers({ page: 1 });

  if (isLoading) return <LoadingOverlay visible />;

  if (users?.data && users.data.length > 0) return <UsersRendererPage initialData={users} />;

  return <EmptyState title="No user yet" />;
}
