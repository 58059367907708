import { Box, Button, Text } from '@axazara/raiton-atoms';
import { CopyOutline, CopySuccessOutline } from '@axazara/raiton-icons';
import { useClipboard } from '@axazara/raiton-utils';

export function CopyApiKey({ apiKey }: { apiKey: string }) {
  const { copy, copied } = useClipboard({ timeout: 500 });

  return (
    <Box className="mt-12">
      <Box className="mb-6">
        <Text className="text-critical-40 text-p-02-semibold mb-6">Keep your key safe</Text>
        <Text className="text-neutral-40 text-p-01">
          Save and store this new key to a secure place, such as a password manager or secret store. You won’t be able
          to see it again.
        </Text>
      </Box>

      <Box className="w-full border border-primary-70 rounded-2xl py-2">
        <Box className="flex justify-between items-center px-2">
          <Text>{`${apiKey.substring(0, 10)}${'*'.repeat(apiKey.length - 10)}`}</Text>
          <Button
            size="xs"
            className="text-primary-50"
            color="primary.8"
            onClick={() => copy(apiKey)}
            leftIcon={
              copied ? (
                <CopySuccessOutline className="w-4 h-4 text-primary-40 ml-2" />
              ) : (
                <CopyOutline className="w-4 h-4 text-primary-40" />
              )
            }
          >
            {copied ? 'copied' : 'copy'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
