import { Box, InputField, Select, Avatar, Button, Text, Textarea } from '@axazara/raiton-atoms';
import { useForm } from '@axazara/raiton-molecules';
import { Creative, CreativePayload, PaginatedApps } from 'utilities';
import { useGetAllSocialMedia, useUpdateCreative } from '../services';
import { useGetApp } from '@/services';
import { showErrorNotification, showSuccessNotification } from '@/utils';
import React from 'react';

export function EditVideoCreativeForm({ creative, apps }: { creative: Creative; apps: PaginatedApps }) {
  const languageData = [
    { label: 'EN', value: 'en' },
    { label: 'FR', value: 'fr' },
  ];

  const creativesType = [{ label: 'Video', value: 'video', color: 'bg-secondary-50' }];

  const updateCreativesForm = useForm<Partial<CreativePayload>>({
    initialValues: {
      title: creative.title,
      youtube_video_id: creative.youtube_video_id,
      language: creative.language,
      social_media: creative.social_media,
      content: creative.content,
      app_id: creative.app.id,
    },
  });
  const useChangeCreativeMutation = useUpdateCreative({ id: creative.id });
  const { data: currentCreativeApp } = useGetApp(creative.app.id);
  const { data: mediaData } = useGetAllSocialMedia();

  async function handleSubmit(values: Partial<CreativePayload>) {
    try {
      if (
        values.title !== creative.title ||
        values.language !== creative.language ||
        values.app_id !== creative.app.id ||
        values.social_media !== creative.social_media ||
        values.content !== creative.content ||
        values.youtube_video_id !== creative.youtube_video_id
      ) {
        const response = await useChangeCreativeMutation.mutateAsync(values);
        showSuccessNotification((response as any)?.message);
      }
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  return (
    <Box component="form" onSubmit={updateCreativesForm.onSubmit((values) => handleSubmit(values))}>
      <InputField
        {...updateCreativesForm.getInputProps('title')}
        label="Creatives name"
        classNames={{
          input: 'rounded-lg',
          label: 'mb-3 text-p-01-semibold text-neutral-40',
        }}
        radius={12}
        placeholder="Creatives name"
        size="xs"
        className="w-full mb-5"
      />

      {apps && currentCreativeApp && (
        <Select
          {...updateCreativesForm.getInputProps('app_id')}
          defaultValue={creative.app.id}
          label="App "
          classNames={{
            input: 'rounded-lg',
            label: 'mb-3 text-p-01-semibold text-neutral-40',
          }}
          placeholder="app"
          data={[...(apps.data as any), currentCreativeApp]}
          optionResolver={(app: any) => ({ label: app.name, value: app.id, icon_url: app.icon_url })}
          type="text"
          size="xs"
          className="mb-5"
          radius={12}
          itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
            <Box className="py-2" ref={ref} key={value} {...others}>
              <Box className="flex flex-row items-center">
                <Avatar size={32} src={others.icon_url} />
                <Text className="text-p-01-medium ml-2">{label}</Text>
              </Box>
            </Box>
          ))}
        />
      )}

      <Select
        {...updateCreativesForm.getInputProps('language')}
        placeholder="language"
        label="Language"
        classNames={{
          input: 'rounded-lg',
          label: 'mb-3 text-p-01-semibold text-neutral-40',
        }}
        data={languageData as any}
        optionResolver={(language: any) => ({ label: language.label, value: language.value })}
        type="text"
        size="xs"
        className="mb-5"
        radius={12}
        itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
          <Box className="py-2" ref={ref} key={value} {...others}>
            <Box className="flex flex-row items-center ">
              <Text className="text-p-01-medium">{label}</Text>
            </Box>
          </Box>
        ))}
        styles={(theme) => ({
          input: {
            borderColor: theme.colors.neutral[6],
          },
        })}
      />
      {mediaData && (
        <Select
          {...updateCreativesForm.getInputProps('social_media')}
          defaultValue={creative.social_media}
          placeholder="media"
          label="Social media"
          classNames={{
            input: 'rounded-lg',
            label: 'mb-3 text-p-01-semibold text-neutral-40',
          }}
          data={mediaData as any}
          optionResolver={(media: any) => ({ label: media.label, value: media.value, icon_url: media.icon_url })}
          type="text"
          size="xs"
          className="mb-5"
          radius={12}
          itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
            <Box className="py-2" ref={ref} key={value} {...others}>
              <Box className="flex flex-row items-center space-x-2 ">
                <Avatar size={32} src={others.icon_url} />
                <Text className="text-p-01-medium">{label}</Text>
              </Box>
            </Box>
          ))}
          styles={(theme) => ({
            input: {
              borderColor: theme.colors.neutral[6],
            },
          })}
        />
      )}
      <InputField
        {...updateCreativesForm.getInputProps('youtube_video_id')}
        label="Youtube video url "
        classNames={{
          input: 'rounded-lg',
          label: 'mb-3 text-p-01-semibold text-neutral-40',
        }}
        radius={12}
        placeholder="Creatives name"
        size="xs"
        className="w-full mb-5"
      />

      <Textarea
        {...updateCreativesForm.getInputProps('content')}
        placeholder="description"
        className="text-p-02 mb-5"
        classNames={{
          input: 'rounded-lg',
          label: 'mb-3 text-p-01-semibold text-neutral-40',
        }}
        label="Description"
        minRows={5}
        radius={8}
        autosize
      />
      <Select
        {...updateCreativesForm.getInputProps('type')}
        placeholder="Type"
        data={creativesType as any}
        optionResolver={(type: any) => ({ label: type.label, value: type.value })}
        classNames={{ label: 'text-neutral-50 mb-2 text-p-02' }}
        type="text"
        size="xs"
        className="mb-5 hidden"
        radius={12}
        itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
          <Box className="py-2" ref={ref} key={value} {...others}>
            <Box className="flex flex-row items-center space-x-2 ">
              <Text className="text-p-01-medium">{label}</Text>
            </Box>
          </Box>
        ))}
        styles={(theme) => ({
          input: {
            borderColor: theme.colors.neutral[6],
          },
        })}
      />
      <Box className="text-center pt-6">
        <Button
          type="submit"
          loading={useChangeCreativeMutation.isLoading}
          disabled={!updateCreativesForm.isDirty()}
          size="xs"
        >
          Update creative
        </Button>
      </Box>
    </Box>
  );
}
