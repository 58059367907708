import { Table, Box } from '@axazara/raiton-atoms';
import { AppsTableRow } from './AppsTableRow';
import { Apps } from 'utilities';

export function AppsTable({ apps }: { apps: Apps }) {
  return (
    <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">App</Table.HeadCell>

          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Visibility</Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">Views</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-neutral-80">
          {apps.map((app) => (
            <AppsTableRow app={app} key={app.id} />
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
}
