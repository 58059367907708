import React from 'react';
import { Box, InputField, Table, Text, Tag, LoadingOverlay, Avatar, ActionIcon } from '@axazara/raiton-atoms';
import { CloseCircleFill, SearchNormal1Outline, TickCircleFill, SearchStatusOutline } from '@axazara/raiton-icons';
import { Link } from 'react-router-dom';
import { useDebouncedValue } from '@axazara/raiton-utils';
import { useGetStaffsData } from '../services';
import { EmptyState, PaginationControls } from '@/components';
import { TableBody } from '@axazara/raiton-atoms/lib/Table/TableBody';

export function StaffsTabRenderer() {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [page, setPage] = React.useState(1);

  const { data: staffs, isLoading } = useGetStaffsData({
    page,
    search: debouncedSearch,
  });

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="flex justify-between mb-8 px-6">
        <InputField
          placeholder="Search staff member"
          size="xs"
          icon={<SearchNormal1Outline className="w-5 h-5" />}
          className="w-full"
          onChange={(e) => setSearchQuery(e.target.value)}
          rightSection={
            debouncedSearch ? (
              <CloseCircleFill
                className="w-4 h-4 text-gray-600"
                onClick={() => {
                  setSearchQuery('');
                }}
              />
            ) : null
          }
          classNames={{
            input: 'rounded-xl',
          }}
        />
      </Box>
      <Box className="px-6">
        <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
          <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
            <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
              <Table.HeadCell className="w-96 text-neutral-20 text-p-01-semibold">Name</Table.HeadCell>
              <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Email</Table.HeadCell>
              <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Status</Table.HeadCell>
              <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">View</Table.HeadCell>
            </Table.Head>
            <TableBody className="divide-y divide-neutral-80 font-poppins">
              {staffs?.data &&
                staffs.data.map((staff) => (
                  <Table.Row className="border-b border-neutral-90" key={staff.id}>
                    <Table.Cell>
                      <Box className="flex items-center">
                        <Avatar src={staff?.profile_photo_url} size={48} className="mr-4" />

                        <Text className="text-neutral-20 text-p-01-medium">{staff.full_name}</Text>
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <Text component="span" className="text-p-01 text-neutral-40">
                        {staff.email}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      {staff.is_active ? (
                        <Tag
                          className="text-p-01 normal-case bg-success-90 py-3"
                          size="lg"
                          leftSection={<TickCircleFill className="w-4 h-4 text-success-30" />}
                        >
                          <Text component="span" className="text-success-30">
                            Active
                          </Text>
                        </Tag>
                      ) : (
                        <Tag
                          className="text-p-01 normal-case bg-critical-50 py-3"
                          size="lg"
                          leftSection={<CloseCircleFill className="w-4 h-4 text-white" />}
                        >
                          <Text component="span" className="text-white">
                            Inactive
                          </Text>
                        </Tag>
                      )}
                    </Table.Cell>
                    <Table.Cell className="flex justify-end">
                      <ActionIcon component={Link} to={`/staffs/${staff.id}`} size="xl" radius={100} className="p-0.5">
                        <SearchStatusOutline className="w-6 h-6 text-primary-50" />
                      </ActionIcon>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </TableBody>
          </Table>
        </Box>
        {staffs?.data && <PaginationControls page={page} pagination={staffs?.pagination} onPageChange={setPage} />}
      </Box>
    </Box>
  );
}

export function StaffsTab() {
  const { data: staffs, isLoading } = useGetStaffsData({ page: 1 });

  if (isLoading) return <LoadingOverlay visible />;

  if (staffs && staffs?.data?.length > 0) return <StaffsTabRenderer />;

  return <EmptyState title="No staff member yet" />;
}
