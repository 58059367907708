import { useDebouncedValue } from '@axazara/raiton-utils';
import React from 'react';
import { PaginatedUsers } from 'utilities';
import { useGetUsers } from '../../services';

export function useUsers({ initialData }: { initialData: PaginatedUsers }) {
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);

  const { data: users, isLoading } = useGetUsers({
    page,
    search: debouncedSearch,
    initialData,
  });

  return {
    users,
    isLoading,

    page,
    setPage,
    searchQuery,
    setSearchQuery,
    debouncedSearch,
  };
}
