import React from 'react';
import {
  Box,
  Text,
  DatePickerInput,
  InputField,
  Button,
  Menu,
  UnstyledButton,
  Group,
  LoadingOverlay,
  Select,
  Avatar,
} from '@axazara/raiton-atoms';
import {
  GalleryOutline,
  ArrowDownOutline,
  Calendar2Outline,
  SearchNormal1Outline,
  AddCircleFill,
  CloseCircleFill,
} from '@axazara/raiton-icons';
import { DateRange } from '@/types';
import { openModal } from '@axazara/raiton-molecules';
import { useFilterDropdownStyles } from '@/utils';
import { classNames, useDebouncedValue } from '@axazara/raiton-utils';
import { PaginatedCreatives } from 'utilities';
import { EmptyState, PaginationControls } from '@/components';
import { useGetAllSocialMedia, useGetCreatives } from '../services';
import ResourcesType from '../components/ResourcesType';
import CreativesTable from '../components/CreativesTable';
import { useSetPageMeta } from '@/hooks';

function CreativesPageRenderer({ creatives }: { creatives: PaginatedCreatives }) {
  const creativesType = [
    { label: 'all', value: 'all', color: 'bg-neutral-50' },
    { label: 'text', value: 'text', color: 'bg-secondary-50' },
    { label: 'image', value: 'image', color: 'bg-success-50' },
    { label: 'video', value: 'video', color: 'bg-primary-50' },
  ];

  const creativesLanguage = [
    { label: 'All', value: 'all' },
    { label: 'En', value: 'en' },
    { label: 'Fr', value: 'fr' },
  ];

  const [opened, setOpened] = React.useState(false);
  const { classes } = useFilterDropdownStyles({ opened });

  const [openedLanguageFilter, setOpenedLanguageFilter] = React.useState(false);

  const { classes: languageFilterClasses } = useFilterDropdownStyles({ opened: openedLanguageFilter, width: 'auto' });

  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>([null, null]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [type, setType] = React.useState<any>(creativesType[0]);
  const [language, setLanguage] = React.useState<any>(creativesLanguage[0]);
  const [page, setPage] = React.useState(1);

  const [mediaFilter, setMediaFilter] = React.useState<string | null>('');

  const { data: creativesData, refetch } = useGetCreatives({
    page,
    search: debouncedSearch,
    initialData: creatives,
    type: type.value,
    language: language.value,
    social_media: mediaFilter ?? '',
    dateRange: selectedDateRange,
  });

  const { data: mediaData } = useGetAllSocialMedia();

  const typeItems = creativesType.map((item) => (
    <Menu.Item
      icon={<Box className={`w-3 h-3 rounded-full ${item.color}`} />}
      onClick={() => setType(item)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  const languageItems = creativesLanguage.map((item) => (
    <Menu.Item onClick={() => setLanguage(item)} key={item.label}>
      {item.label}
    </Menu.Item>
  ));

  return (
    <Box>
      <Box className="pt-4">
        <Box className="flex justify-end mb-6">
          <Button
            onClick={() =>
              openModal({
                children: <ResourcesType />,
                title: (
                  <Text className="text-heading-05-bold text-neutral-30">
                    Choisissez le type de ressource à ajouter
                  </Text>
                ),
                size: 'lg',
                closeOnClickOutside: true,
                centered: true,
              })
            }
            leftIcon={<AddCircleFill className="w-6 h-6" />}
            size="xs"
          >
            Add creative
          </Button>
        </Box>
        <Box className="flex flex-row w-full justify-between mb-6">
          <Box className="flex gap-2 w-full">
            <InputField
              placeholder="search"
              size="xs"
              icon={<SearchNormal1Outline className="w-5 h-5" />}
              className="w-full"
              onChange={(e) => setSearchQuery(e.target.value)}
              rightSection={
                debouncedSearch ? (
                  <CloseCircleFill
                    className="w-4 h-4 text-gray-600"
                    onClick={() => {
                      setSearchQuery('');
                    }}
                  />
                ) : null
              }
            />
            <Box className="flex flex-row gap-2">
              <Box className="w-full">
                {mediaData && (
                  <Select
                    withinPortal
                    data={mediaData.map((media) => ({
                      label: media.label,
                      value: media.value,
                      icon_url: media.icon_url,
                    }))}
                    classNames={{ label: 'mb-3 text-p-01-semibold text-dark', root: 'w-max' }}
                    onChange={setMediaFilter}
                    value={mediaFilter}
                    rightSection={
                      mediaFilter ? (
                        <CloseCircleFill
                          className="w-4 h-4 text-neutral-40 cursor-pointer"
                          onClick={() => setMediaFilter('')}
                        />
                      ) : (
                        <ArrowDownOutline className="w-4 h-4 text-neutral-40" />
                      )
                    }
                    size="xs"
                    radius={12}
                    placeholder="Filter by media"
                    styles={(theme) => ({
                      input: {
                        borderColor: theme.colors.neutral[6],
                      },
                      item: {
                        '&[data-selected]': {
                          backgroundColor: theme.colors.neutral[9],
                          color: theme.black,
                        },
                      },
                      dropdown: {
                        borderColor: theme.colors.neutral[7],
                      },
                    })}
                    itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
                      <Box className="py-2" key={value} ref={ref} {...others}>
                        <Box className="flex flex-row items-center space-x-2 ">
                          <Avatar size={32} src={others.icon_url} />
                          <Text className="text-p-01-medium">{label}</Text>
                        </Box>
                      </Box>
                    ))}
                  />
                )}
              </Box>
              <Box className="w-full">
                <Menu
                  onOpen={() => setOpened(true)}
                  onClose={() => setOpened(false)}
                  radius="md"
                  width="target"
                  withinPortal
                >
                  <Menu.Target>
                    <UnstyledButton className={classes.control}>
                      <Group spacing="xs">
                        <Box className={`w-3 h-3 rounded-full ${type?.color}`} />
                        <span className={classes.label}>{type?.label}</span>
                      </Group>
                      <ArrowDownOutline className={classNames(classes.icon, 'w-4 h-4 text-neutral-40')} />
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>{typeItems}</Menu.Dropdown>
                </Menu>
              </Box>
              <Box className="w-full">
                <Menu
                  onOpen={() => setOpenedLanguageFilter(true)}
                  onClose={() => setOpenedLanguageFilter(false)}
                  radius="md"
                  width="target"
                  withinPortal
                >
                  <Menu.Target>
                    <UnstyledButton className={`${languageFilterClasses.control} space-x-3`}>
                      <Group spacing="xs">
                        <span className={languageFilterClasses.label}>{language?.label}</span>
                      </Group>
                      <ArrowDownOutline className={classNames(languageFilterClasses.icon, 'w-4 h-4 text-neutral-40')} />
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>{languageItems}</Menu.Dropdown>
                </Menu>
              </Box>
              <DatePickerInput
                size="xs"
                type="range"
                clearable
                className="w-full"
                icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
                // @ts-ignore
                placeholder="Filter by date"
                value={selectedDateRange}
                numberOfColumns={2}
                styles={(theme) => ({
                  root: {
                    '> div': {
                      borderColor: theme.colors.neutral[8],
                    },
                  },
                })}
                onChange={setSelectedDateRange}
                allowSingleDateSelectionInRange
                rightSection={selectedDateRange[1] ? null : <ArrowDownOutline className="w-4 h-4 text-neutral-40" />}
              />
            </Box>
          </Box>
        </Box>
        {creativesData?.data && (
          <>
            <CreativesTable creatives={creativesData.data} refetch={refetch} />
            <PaginationControls page={page} pagination={creativesData?.pagination} onPageChange={setPage} />
          </>
        )}
      </Box>
    </Box>
  );
}

export function Creatives() {
  useSetPageMeta({
    title: 'Creatives',
    icon: GalleryOutline,
  });
  const { data: creativesData, isLoading } = useGetCreatives({});

  if (isLoading) return <LoadingOverlay visible={isLoading} loaderProps={{ color: 'secondary' }} />;

  if (creativesData && creativesData?.data?.length > 0) return <CreativesPageRenderer creatives={creativesData} />;

  return (
    <EmptyState
      title="No creatives yet"
      primaryAction={
        <Button
          onClick={() =>
            openModal({
              children: <ResourcesType />,
              title: (
                <Text className="text-heading-05-bold text-neutral-30">Choisissez le type de ressource à ajouter</Text>
              ),
              size: 'lg',
              closeOnClickOutside: true,
              centered: true,
            })
          }
          leftIcon={<AddCircleFill className="w-6 h-6" />}
          size="xs"
        >
          Add creative
        </Button>
      }
    />
  );
}
