import { createStyles } from '@axazara/raiton-styles';

export const useFilterDropdownStyles = createStyles(
  (theme, { opened, width }: { opened: boolean; width?: number | string }) => ({
    control: {
      width: width || 160,
      height: 40,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 12px',
      borderRadius: 12,
      border: `1px solid ${theme.colors.neutral[7]}`,
      transition: 'background-color 150ms ease',
      backgroundColor: theme.white,
      '@media (max-width: 670px)': {
        width: '100%',
      },
    },

    label: {
      fontWeight: 500,
      fontSize: theme.fontSizes.xs,
    },

    icon: {
      transition: 'transform 150ms ease',
      transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
    },
  })
);
