import { SimplifiedApp } from './app';
import { BaseEntity } from './base';
import { PaginatedData, PaginatedType } from './pagination';

export interface Creative extends BaseEntity {
  title: string;
  app: SimplifiedApp;
  type: string;
  content: string;
  image_url: string;
  video_url: string;
  youtube_video_id: string;
  is_public: boolean;
  language: string;
  social_media: string;
}

export type Creatives = Creative[];

export type PaginatedCreatives = PaginatedType<PaginatedData, 'data', Creatives>;

export enum CreativeType {
  all = 'all',
  image = 'image',
  text = 'text',
  video = 'video',
}

export enum CreativeMedias {
  all = 'all',
  facebook = 'facebook',
  instagram = 'instagram',
  twitter = 'twitter',
  tiktok = 'tiktok',
  youtube = 'youtube',
  snapchat = 'snapchat',
  linkedin = 'linkedin',
  whatsapp = 'whatsapp',
}

export enum CreativeLanguages {
  all = 'all',
  en = 'en',
  fr = 'fr',
}
export interface SocialMedia {
  label: string;
  value: string;
  icon_url: string;
}

export type AllSocialMedia = SocialMedia[];

export interface CreativePayload {
  title: string;
  app_id: string;
  content: string;
  image: string;
  image_url: string;
  youtube_video_id: string;
  is_public: boolean;
  language: string;
  social_media: string;
  type: string;
}
