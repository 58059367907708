export default {
  dashboard: 'Dashboard',
  payments: 'Payments',
  payouts: 'Payouts',
  customers: 'Customers',
  'layout.sidebar.statistique': 'Statistics',
  settings: 'Settings',
  Team: 'Team',
  developers: 'Developers',
  sandbox: 'Sandbox Mode',
  enable_app: 'Activate Your App',
  enable_app_description:
    'Your app is not yet activated. Activate your app to put it online and receive payments from your customers.',
  enable_the_app: 'Activate the App',
  transactions: 'Transactions',
  gateways: 'Gateways',
  methods: 'Methods',
  documentation: 'Documentation',
  helpCenter: 'Helpcenter',
  tutorials: 'Tutorials',
  collapse_sidebar: 'Collapse sidebar',
};
