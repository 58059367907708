import { Box, Button } from '@axazara/raiton-atoms';
import { Creative } from 'utilities';

export function CreativeImageItem({ creative }: { creative: Creative }) {
  return (
    <Box className="space-y-4">
      <Box key={creative.id}>
        <Box className="flex flex-col justify-between gap-4 border rounded-3xl p-4 border-neutral-90">
          <Box className="flex flex-col gap-3 md:mb-2">
            <Box className="h-48 relative">
              <img src={creative.image_url} alt="image" className="rounded-3xl object-cover h-48 w-full" />
            </Box>
          </Box>
          <Button component="a" href={creative.image_url} size="xs" target="_blank">
            Download image
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
