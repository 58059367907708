import { Box, Table, InputField, DatePickerInput, LoadingOverlay } from '@axazara/raiton-atoms';
import { SearchNormal1Outline, ArrowDownOutline, CloseCircleFill, Calendar2Outline } from '@axazara/raiton-icons';
import { useDebouncedValue } from '@axazara/raiton-utils';
import React from 'react';
import { DateRange } from '@/types';
import { EmptyState, PaginationControls } from '@/components';
import { useGetUserReferralLinks } from '@/features';
import { ReferralsLiksTabRow } from './ReferralsLiksTabRow';

export function ReferralsLinksRenderer({ id }: { id: string }) {
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>([null, null]);

  const { data: referralsLinksData, isLoading } = useGetUserReferralLinks(id, {
    page,
    search: debouncedSearch,
    dateRange: selectedDateRange,
  });

  return (
    <Box className="px-5">
      <LoadingOverlay visible={isLoading} loaderProps={{ color: 'secondary' }} />
      <Box>
        <Box className="flex flex-row w-full justify-between mb-8">
          <Box className="flex gap-2 w-full">
            <InputField
              classNames={{ input: 'rounded-xl' }}
              radius={12}
              placeholder="seach link"
              size="xs"
              icon={<SearchNormal1Outline className="w-5 h-5" />}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full"
              value={searchQuery}
              rightSection={
                <Box>
                  {debouncedSearch ? (
                    <CloseCircleFill
                      className="w-4 h-4 text-neutral-40"
                      onClick={() => {
                        setSearchQuery('');
                      }}
                    />
                  ) : null}
                </Box>
              }
            />
            <Box className="flex flex-row gap-2">
              <DatePickerInput
                size="xs"
                type="range"
                clearable
                icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
                //@ts-ignore
                placeholder="Filter by date"
                value={selectedDateRange}
                numberOfColumns={2}
                styles={(theme) => ({
                  root: {
                    '> div': {
                      borderColor: theme.colors.neutral[8],
                    },
                  },
                })}
                onChange={(value) => {
                  setSelectedDateRange(value);
                }}
                allowSingleDateSelectionInRange
                rightSection={selectedDateRange[1] ? null : <ArrowDownOutline className="w-4 h-4 text-neutral-40" />}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className="relative">
            <LoadingOverlay visible={true} loaderProps={{ color: 'secondary' }} />
          </Box>
          <Box className="w-full overflow-hidden rounded-2xl border border-neutral-80">
            <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
              <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
                <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Reference</Table.HeadCell>
                <Table.HeadCell className="text-neutral-20 text-p-01-semibold">App</Table.HeadCell>
                <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Date</Table.HeadCell>
                <Table.HeadCell className="text-neutral-20 text-p-01-semibold">View</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y divide-neutral-80">
                {referralsLinksData?.data?.map((link) => {
                  return <ReferralsLiksTabRow link={link} key={link.id} />;
                })}
              </Table.Body>
            </Table>
          </Box>
          {referralsLinksData && (
            <PaginationControls page={page} pagination={referralsLinksData?.pagination} onPageChange={setPage} />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export function ReferralsLinksTab({ id }: { id: string }) {
  const { data: referralsLinksData, isLoading } = useGetUserReferralLinks(id, {});

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  if (referralsLinksData && referralsLinksData?.data?.length > 0) return <ReferralsLinksRenderer id={id} />;

  return <EmptyState title="No referrals links yet" />;
}
