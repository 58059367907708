import { Box, Text, Tag, Surface, Divider } from '@axazara/raiton-atoms';
import { Profile2userOutline, Wallet3Outline } from '@axazara/raiton-icons';
import { StatsSummary, User, formatDate } from 'utilities';
import { useTranslation } from 'react-i18next';
import { UserStateBadge } from './UserStateBadge';

export function UserInformationsTab({
  userInformations,
  userStats,
}: {
  userInformations: User;
  userStats: StatsSummary;
}) {
  const { t } = useTranslation();

  return (
    <Box className="px-8 pb-8">
      <Surface radius={16} className="bg-white">
        <Box className="flex gap-6 mb-8">
          <Surface radius={16} className="bg-primary w-full p-6">
            <Box className="bg-neutral-10 rounded-full w-11 h-11 flex justify-center items-center mb-2">
              <Wallet3Outline className="w-5 h-5 text-white" />
            </Box>
            <Text className="text-p-01-medium mb-2">Total earnings</Text>
            <Text component="span" className="text-heading-05-bold">
              {userStats?.total_earnings}
            </Text>
          </Surface>
          <Surface radius={16} className="bg-secondary w-full p-6">
            <Box className="bg-neutral-10 rounded-full w-11 h-11 flex justify-center items-center mb-2">
              <Profile2userOutline className="w-5 h-5 text-white" />
            </Box>
            <Text className="text-p-01-medium mb-2">Total referrals</Text>
            <Text component="span" className="text-heading-05-bold">
              {userStats?.total_referrals}
            </Text>
          </Surface>
        </Box>
      </Surface>
      <Divider color="neutral.7" className={'border-neutral-90 mb-8'} />
      <Box className="mb-36 mx-auto max-w-2xl">
        <Box className="space-y-4">
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">ID</Text>
            <Text className="text-neutral-30 text-p-01">{userInformations?.id}</Text>
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">First name</Text>
            <Text className="text-neutral-30 text-p-01">{userInformations?.first_name}</Text>
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">Last name</Text>
            <Text className="text-neutral-30 text-p-01">{userInformations?.last_name}</Text>
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">Email</Text>
            <Text className="text-neutral-30 font-semibold text-p-01">{userInformations?.email}</Text>
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">Sing up at</Text>
            <Text className="text-neutral-30 text-p-01">
              {formatDate(userInformations?.created_at, 'en', 'D MMM YYYY, HH:mm')}
            </Text>
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">Last login at</Text>
            <Text className="text-neutral-30 text-p-01">
              {formatDate(userInformations?.last_login_at, 'en', 'D MMM YYYY, HH:mm')}
            </Text>
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">State</Text>
            <UserStateBadge state={userInformations?.state} />
          </Box>

          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">Identity status</Text>
            {userInformations?.identity_is_verified ? (
              <Tag className="text-p-01-semibold normal-case bg-success border border-success-60 py-3" size="lg">
                <Text component="span" className="text-success-30 text-p-01-semibold">
                  {t('verified')}
                </Text>
              </Tag>
            ) : (
              <Tag className="text-p-01-semibold normal-case bg-success border border-success-60 py-3" size="lg">
                <Text component="span" className="text-success-30 text-p-01-semibold">
                  {t('unverified')}
                </Text>
              </Tag>
            )}
          </Box>
          <Box className="flex flex-row items-center justify-between">
            <Text className="text-neutral-50 font-semibold text-p-01-medium w-40">Two factor status</Text>
            <Box>
              {userInformations?.identity_is_verified ? (
                <Tag className="text-p-01-semibold normal-case bg-success border border-success-60 py-3" size="lg">
                  <Text component="span" className="text-success-30 text-p-01-semibold">
                    {t('enable')}
                  </Text>
                </Tag>
              ) : (
                <Tag className="text-p-01-semibold normal-case bg-critical border border-critical-60 py-3" size="lg">
                  <Text component="span" className="text-critical-30 text-p-01-semibold">
                    {t('disable')}
                  </Text>
                </Tag>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
