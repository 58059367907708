import { LinkInfos } from './LinkInfos';
import { Table, Box, Avatar, ActionIcon, Text } from '@axazara/raiton-atoms';
import { SearchStatusOutline } from '@axazara/raiton-icons';
import { openModal } from '@axazara/raiton-molecules';
import { t } from 'i18next';
import { ReferralLink, formatDate } from 'utilities';

export function ReferralsLiksTabRow({ link }: { link: ReferralLink }) {
  return (
    <Table.Row key={link?.id} className="border-b border-neutral-90">
      <Table.Cell className="text-p-01-medium text-neutral-40">{link?.id}</Table.Cell>
      <Table.Cell>
        <Box className="md:flex-row flex flex-col items-center space-x-4">
          <Avatar src={link?.app?.icon_url} size={32} />
          <Box>
            <Text className="text-p-01-semibold">{link?.app?.name}</Text>
          </Box>
        </Box>
      </Table.Cell>

      <Table.Cell className="text-p-01-medium">{formatDate(link?.created_at, 'en', 'D MMM YYYY, HH:mm')}</Table.Cell>
      <Table.Cell>
        <Box
          onClick={() =>
            openModal({
              children: <LinkInfos link={link} />,
              title: <Text className="text-heading-06-bold text-primary-40">{t('referral_link_details_tilte')}</Text>,
              size: 'lg',
              padding: 32,
              closeOnClickOutside: true,
            })
          }
          className="flex space-x-2"
        >
          <ActionIcon color="primary" size="xl" radius={100} className="p-0.5">
            <SearchStatusOutline className="w-6 h-6 text-primary-50" />
          </ActionIcon>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}
