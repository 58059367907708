export default {
  // 1. General Component Labels
  'component.name': 'Nom',
  'component.status': 'Statut',
  'component.description': 'Description',
  'component.currency': 'Devise',
  'component.date': 'Date',
  'component.language': 'Langue',
  'component.amount': 'Montant',
  'component.No': 'N°',
  'component.unknownError': 'Une erreur est survenue, veuillez réessayer',
  'component.All': 'Tout',
  'component.Active': 'Actif',
  'component.Inactive': 'Inactif',
  'component.onceTransactionIsConfirmed': "Le client n'a pas encore effectué le paiement.",
  'component.filterByDate': 'Filtrer par date',
  'component.informations': 'Informations',
  'component.payments': 'Paiements',
  'component.payouts': 'Transferts',
  'component.customer': 'Client',
  'component.customers': 'Clients',
  'component.reference': 'Référence',
  'component.customerSearchPlaceholder': 'Rechercher un client',
  'component.developers': 'Développeurs',
  'component.copy': 'Copier',
  'component.action': 'Action',
  'component.copied': 'Copié',
  'component.search': 'Rechercher',
  'component.edit': 'Modifier',
  'component.object': 'Objet',
  'component.gateway': 'Passerelle',
  'component.settings': 'Réglages',
  'component.showingResults': 'Affichage des résultats {{from}} à {{to}} sur un total de {{total}}',
  showingResults: 'Affichage des résultats {{from}} à {{to}} sur un total de {{total}}',

  'component.URL': 'URL',
  'component.method': 'Méthode',
  'component.revoke': 'Révoquer',
  'component.createdAt': 'Créé le',
  'component.signAt': "S'inscrire à",
  'component.revokedAt': 'Révoqué le',
  'component.expireAt': 'Expire le',
  'component.lastLogin': 'Dernière connexion',
  'component.appName': "Nom de l'application",
  'component.website': 'Site Web',
  'component.cancel': 'Annuler',
  'component.never': 'Jamais',
  'component.admin': 'Administrateur',
  'component.developer': 'Développeur',
  'component.member': 'Membre',
  'component.webhooks': 'Webhooks',
  'component.verified': 'Vérifier',
  'component.unverified': 'Non vérifié',
  'component.enable': 'Activer',
  'component.disable': 'Inactive',
  'component.2faStatus': 'Two factor status',

  // 2. Transaction Management
  'component.allTransactions': 'Voir toutes les transactions',
  'component.transactionPending': 'En attente',
  'component.transactionFailed': 'Échec',
  'component.transactionSuccess': 'Succès',
  'component.transactionInitiated': 'Initié',
  'component.transactionCancelled': 'Rejeté',
  'component.transactionSearchPlaceholder': 'Rechercher une transaction',

  // 3. Payment Details
  'component.paymentDetails.id': 'ID de la transaction',
  'component.paymentContext': 'Contexte',
  'component.ip': 'Adresse IP',
  'component.device': 'Appareil',
  'component.metadata': 'Meta données',
  'component.paymentContext.browser': 'Navigateur',
  'component.paymentContext.language': 'Langue',

  // 4. Payment and Payout Methods
  'component.methodAlreadyAdded': 'Cette méthode est déjà liée à vos applications.',
  'component.paymentMethod.search': 'Rechercher une méthode de paiement',
  'component.paymentMethod.addButton': 'Ajouter {{method}}',
  'component.paymentMethod.compatibleGatewaySuggestion.empty':
    'Votre application n’a aucune passerelle de paiement compatible disponible. Veuillez d’abord ajouter une passerelle de paiement.',
  'component.paymentMethod.linkToYourApp': "Lier {{method}} à l'application",
  'component.paymentMethod.updateLinkedPaymentGateway': 'Mettre à jour la passerelle de paiement',
  'component.paymentMethod.linkPaymentGateway': 'Lier la passerelle de paiement',
  'component.paymentMethod.add': 'Ajouter une méthode de paiement',
  'component.payoutMethod.compatibleGatewaySuggestion.empty':
    'Votre application n’a aucune passerelle de paiement compatible disponible. Veuillez d’abord ajouter une passerelle de paiement.',
  'component.payoutMethod.linkToYourApp': "Lier {{method}} à l'application",
  'component.payoutMethod.linkPayoutGateway': 'Lier la passerelle de paiement',
  'component.payoutMethod.add': 'Ajouter une méthode de paiement',

  // 5. Payment and Payout Gateway Management
  'component.paymentGateway.id': 'Id de la transaction',
  'component.paymentGateway.status': 'Statut de la transaction',
  'component.paymentGateway.availableList': 'Passerelles de paiement disponibles',
  'component.paymentGateway.searchPlaceholder': 'Recherchez une passerelle de paiement',
  'component.paymentGateway.selectPlaceholder': 'Sélectionnez une passerelle de paiement',
  'component.paymentGateway': 'Passerelle de paiement',
  'component.paymentGateway.add': 'Ajouter une passerelle de paiement',
  connect: 'Connecter {{gateway}}',
  'component.payoutGateway.availableList': 'Passerelles de transfert disponibles',
  'component.payoutGateway.searchPlaceholder': 'Recherchez une passerelle de transfert',
  'component.payoutGateway.selectPlaceholder': 'Sélectionnez une passerelle de paiement',
  'component.payoutGateway': 'Passerelle de paiement',
  filter_by_method: 'Filter by method',
  filter_by_gateway: 'Filter by gateway',
  filter_by_currency: 'Filter by currency',
  filter_by_date: 'Filter by date',
  search_transaction: 'Search transaction',

  // 6. Customer Details
  'component.viewCustomer': 'Voir client',
  'component.firstName': 'Prénom',
  'component.lastName': 'Nom de famille',
  'component.email': 'Email',
  'component.phone': 'Téléphone',
  'component.address': 'Adresse',
  'component.city': 'Ville',
  'component.zipCode': 'Code postal',
  'component.country': 'Pays',
  'component.state': 'État',

  // 7. API Details
  'component.apiKeys': 'Clés API',
  'component.apiKey.deleted': 'Clé API supprimée avec succès!',
  'component.webhook': 'Webhook',
  'component.webhookUrl': 'URL du Webhook',
  'component.webhookSecret': 'Secret du Webhook',
  'component.addedBy': 'Ajouté par',

  // 8. Team Management
  'component.team.members': 'Membres de l’équipe',
  'component.team.member': 'Membre de l’équipe',
  'component.team.invites': 'Invitations',
  'component.team.inviteAt': 'Inviter le',
  'component.team.invitedBy': 'Invité par',
  'component.team.Add': 'Ajouter un membre',
  'component.team.invite': 'Inviter',
  'component.role': 'Rôle',

  // 9. Settings
  'component.settingsTab.transactionEmail': 'Emails de transaction',
  'component.settingsTab.customerEmail': 'Reçu du client',
  'component.settingsTab.payoutEmail': 'Emails de paiement',
  'component.settingsTab.supportEmail': 'Email du support',
  'component.settingsTab.supportPhone': 'Téléphone du support',
  'component.settingsTab.transactionEmail.description': "Envoyez-moi un email lorsqu'une transaction est effectuée.",
  'component.settingsTab.customerEmail.description': "Envoyer un email au client lorsqu'une transaction est effectuée.",
  'component.settingsTab.payoutEmail.description': "Envoyez-moi un email lorsqu'un paiement est effectué.",

  'component.compatibleGatewaySuggestion': 'Certaines passerelles qui supportent cette méthode',

  'component.addedAt': 'Ajouté le',
  'component.view': 'Voir',

  'component.supportedCurrencies': 'Devises prises en charge',
  'component.supportedMethods': 'Méthodes prises en charge',
  'component.supportedCountries': 'Pays pris en charge',
  'component.visitWebsite': 'Visiter le site Web',
  'component.learnMore': 'En savoir plus',
  'component.supportedGateways': 'Passerelles prises en charge',
  'component.sandboxMode': 'Mode sandbox',

  'component.webhookDescription':
    "Veuillez ajouter cette URL de webhook à votre compte. Cela permettra à Moneroo de recevoir une notification de la passerelle chaque fois qu'une transaction est effectuée ou qu'un événement se produit.",
  'component.updateInformations': 'Mettre à jour les informations',
  'component.updateSettings': 'Mettre à jour les paramètres',
  'component.updateGatewayInformation': 'Mettre à jour les informations',

  'component.profile.edit.description':
    'Pour modifier les informations de votre profil, vous serez redirigé vers votre compte Axa Zara.',

  //Gateways
  'component.accountName': 'Nom du compte',
  'component.developer.createApiKey': 'Créer une clé API',
  'component.developer.keyType': 'Type',
  'component.developer.ApiKeyDetails': 'Détails de la clé API',
  'component.developer.lastUsed': 'Dernière utilisation',

  //Utilities
  'component.team.auditLogs': 'Journaux d’audit',
  'component.team.inviteDetails': 'Détails de l’invitation',
  'component.admin.description': 'Gère tous les aspects des applications.',
  'component.developer.description': 'Gère les paramètres des développeurs, les passerelles et les méthodes.',
  'component.member.description': 'Peut seulement voir les traductions, les passerelles et les méthodes.',

  'component.apiKey.description.placeholder': "Décrivez cette clé et l'endroit où elle est sera stockée.",
  'component.apiKey.type.placeholder': 'Sélectionnez un type de clé',
  'component.apiKey.expiresAt.placeholder': 'Date d’expiration',
  'component.apiKey.keepItSafe': 'Conservez votre clé en lieu sûr',
  'component.apiKey.copy.title': 'Votre nouvelle clé API',
  'component.apiKey.private': 'Privé',
  'component.apiKey.public': 'Public',
  'component.apiKey.keepItSafe.description':
    'Veuillez conserver cette clé secrète dans un endroit sûr et accessible. Pour des raisons de sécurité, <a>vous ne pourrez pas la consulter à nouveau </a> via votre compte Moneroo. Si vous perdez cette clé secrète, vous devrez en générer une nouvelle.',
  'component.apiKey.expiresAt.note':
    'Vous pouvez définir une date d’expiration pour cette clé. Si vous ne définissez pas de date, la clé n’expirera jamais.',

  'component.developer.addWebhook': 'Ajouter un webhook',
  'component.webhook.add': 'Ajouter un nouveau webhook',
  'component.webhook.secret': 'Hash secret',
  'component.emptyWebhook': "Aucun webhook n'a encore été ajouté",

  'component.webhook.delete': 'Supprimer le webhook',
  'component.webhook.deleteMessage': 'Êtes-vous sûr de vouloir supprimer ce webhook ? Cette action est irréversible.',
  'component.webhook.deleted': 'Le webhook a été supprimé avec succès !',

  'component.apiKey.delete': 'Supprimer la clé API',
  'component.apiKey.deleteMessage':
    'Êtes-vous sûr de vouloir supprimer cette clé API ? Cette action ne peut pas être annulée.',

  'component.transaction.initiatedNotice': 'Plus de détails seront disponibles une fois le statut du paiement modifié.',
  'component.createApp': 'Créer une nouvelle application',

  'component.auditLogsTab.action': 'Action',
  'component.auditLogsTab.details': "Détails de l'activité",

  'component.transactionsTab.reference': 'Reference',

  'component.oneMoreStep': 'Une étape de plus',
  'component.oneMoreStep.description':
    "Veuillez ajouter cette URL de webhook à votre compte. Cela permettra à Moneroo de recevoir une notification de la passerelle chaque fois qu'une transaction est effectuée ou qu'un événement se produit.",

  'component.no': 'Non',
  'component.yes': 'Oui',
  'component.done': 'Terminé',

  'component.didYouCopy': 'Avez-vous copié le lien ?',
  'component.didYouCopy.description':
    'Veuillez vous assurer que vous avez ajouté le lien à votre passerelle de paiement. Vous pouvez toujours le trouver dans les paramètres de la passerelle.',
};
