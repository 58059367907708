import { Box, Button, Surface, Text } from '@axazara/raiton-atoms';
import { CopySuccessFill, CopyOutline } from '@axazara/raiton-icons';
import { AuthInvite } from 'utilities';

export function InvitationDetails({
  invitation,
  copy,
  copied,
}: {
  invitation: AuthInvite;
  copy: any;
  copied: boolean;
}) {
  return (
    <Box className="mt-10">
      <Box className="text-center">
        <Text className="text-primary-40 text-heading-02-sm-bold mb-5">
          <span className="text-neutral-30">Bienvenue </span> {invitation.first_name} {invitation.last_name}
        </Text>
        <Box className="flex justify-center">
          <Text component="p" className="text-p-01-medium w-3/4 mb-16">
            Veuillez suivre attentivement les étapes suivantes pour configurer votre compte.
          </Text>
        </Box>
      </Box>
      <Box className="text-center text-primary-40">
        <Text className="text-heading-03-sm-bold">Étape 1</Text>
        <Text className="text-heading-05-sm mb-12">Configurez la vérification en deux étapes</Text>
        <Text className="text-neutral-40 text-p-01-medium mb-8">
          Veuillez utiliser votre application d’authentification (telle que Duo ou Google Authenticator) pour scanner ce
          code QR.
        </Text>
        <Box className="flex justify-center mb-10">
          <img src={invitation.two_factor_secret_qr_code} width={150} height={150} alt="" />
        </Box>
      </Box>
      <Surface className="border border-neutral-70 p-8 mb-16">
        <Box>
          <Text className="mb-7">Ou entrez ce code dans votre application d’authentification</Text>
          <Box className="flex justify-between">
            <Box>
              <Text component="p" className="text-neutral-70 text-p-01-semibold mb-2">
                Key
              </Text>
              <Text component="p" className="text-p-01-semibold">
                {invitation.two_factor_secret}
              </Text>
            </Box>
            <Button
              onClick={() => copy(invitation.two_factor_secret)}
              leftIcon={copied ? <CopySuccessFill className="w-4 h-4" /> : <CopyOutline className="w-4 h-4" />}
              variant="outline"
              className="text-caption-semibold"
            >
              Copier
            </Button>
          </Box>
        </Box>
      </Surface>
      <Box className="text-center text-primary-40">
        <Text className="text-heading-03-sm-bold">Étape 2</Text>
        <Text className="text-heading-05-sm mb-12">Finalisez la configuration du compte</Text>
        <Box className="flex justify-center">
          <Text className="text-neutral-40 text-p-01-medium w-3/4 mb-8">
            Veuillez entrer le code de confirmation que vous voyez sur l’application d’authentification
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
