import { ApiEndpoints } from '@/configs';
import { useFetch, useUpdate, usePost } from '@/lib';
import { DateRange } from '@/types';
import { buildFetchParams } from '@axazara/raiton-utils';
import {
  AllSocialMedia,
  Creative,
  CreativeLanguages,
  CreativePayload,
  CreativeType,
  PaginatedCreatives,
  formatDate,
} from 'utilities';

export const useGetCreatives = ({
  page,
  search,
  initialData,
  dateRange = [null, null],
  type = CreativeType.all,
  language = CreativeLanguages.all,
  social_media = '',
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedCreatives;
  type?: string;
  language?: string;
  social_media?: string;
  dateRange?: DateRange;
}) => {
  const [fromDate, toDate] = dateRange;

  let params = buildFetchParams({
    page,
    search,
    type,
    language,
    social_media,
    from: fromDate && toDate && formatDate(fromDate.toDateString(), undefined, 'YYYY-MM-DD'),
    to: fromDate && toDate && formatDate(toDate.toDateString(), undefined, 'YYYY-MM-DD'),
  });

  return useFetch<PaginatedCreatives>(`${ApiEndpoints.creatives}`, params, { initialData });
};

export const useGetCreativesDetails = ({ id }: { id: string }) => useFetch<Creative>(`${ApiEndpoints.creatives}/${id}`);

export const useUpdateCreativesPublicStatus = ({ id }: { id: string }) =>
  useUpdate<Partial<Creative>, Creative>(`${ApiEndpoints.creatives}/${id}`);

export const useUpdateCreative = ({ id }: { id: string }) =>
  useUpdate<Partial<CreativePayload>, Creative>(`${ApiEndpoints.creatives}/${id}`);

export const useAddCreative = () =>
  usePost<FormData | Partial<CreativePayload>, Creative>(`${ApiEndpoints.creatives}`, undefined, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const useGetAllSocialMedia = () => useFetch<AllSocialMedia>(`${ApiEndpoints.socialMedia}`);
