import { Tag, Text } from '@axazara/raiton-atoms';
import { TickCircleOutline, CloseCircleOutline } from '@axazara/raiton-icons';
import { UserAccountState } from 'utilities';

export function UserStateBadge({ state }: { state?: UserAccountState }) {
  if (state === UserAccountState.ACTIVE) {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-success border border-success-90 py-3 pl-1 pr-2"
        size="lg"
        leftSection={<TickCircleOutline className="w-4 h-4 text-success-30" />}
      >
        <Text component="span" className="text-success-30 text-p-01-semibold ml-0.5">
          Active
        </Text>
      </Tag>
    );
  }
  return (
    <Tag
      className="text-p-01-semibold normal-case bg-critical border border-critical-90 py-3 pl-1 pr-2"
      size="lg"
      leftSection={<CloseCircleOutline className="w-4 h-4 text-critical-40" />}
    >
      <Text component="span" className="text-critical-30 text-p-01-semibold ml-0.5">
        Inactive
      </Text>
    </Tag>
  );
}
