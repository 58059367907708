import { useState } from 'react';
import { Box, Table, Text, ActionIcon, LoadingOverlay, DatePickerInput } from '@axazara/raiton-atoms';
import { openModal } from '@axazara/raiton-molecules';
import { SearchStatusOutline, Calendar2Outline, ArrowDownOutline } from '@axazara/raiton-icons';
import { DatesRangeValue } from '@axazara/raiton-atoms/lib/date-utils';
import { ActivityDetailsModal } from './ActivityDetailsModal';
import { useGetActivities } from '../services';
import { DateRange } from '@/types';
import { EmptyState, PaginationControls } from '@/components';
import { Activity, formatDate } from 'utilities';

function DatePickerSection({
  selectedDateRange,
  setSelectedDateRange,
}: {
  selectedDateRange: DateRange;
  setSelectedDateRange: (value: DatesRangeValue) => void;
}) {
  return (
    <Box className="flex justify-end mb-8 space-x-4 px-6">
      <DatePickerInput
        size="xs"
        type="range"
        // @ts-ignore
        placeholder="Filter by date"
        clearable
        icon={<Calendar2Outline className="w-4 h-4 text-neutral-40" />}
        value={selectedDateRange}
        numberOfColumns={2}
        styles={(theme) => ({
          root: {
            '> div': {
              borderColor: theme.colors.neutral[8],
            },
          },
        })}
        onChange={setSelectedDateRange}
        allowSingleDateSelectionInRange
        rightSection={selectedDateRange[1] ? null : <ArrowDownOutline className="w-4 h-4 text-neutral-40" />}
      />
    </Box>
  );
}

function ActivityRow({ activity, index }: { activity: Activity; index: number }) {
  return (
    <Table.Row className="border-b border-neutral-90" key={activity.id}>
      <Table.Cell className="w-20">
        <Text component="span" className="text-p-01-semibold">
          {index + 1}
        </Text>
      </Table.Cell>
      <Table.Cell className="w-60">
        <Text component="span" className="text-p-01-semibold">
          {formatDate(activity.created_at, 'en')}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text className="text-neutral-40 text-p-01-semibold">{activity.action}</Text>
      </Table.Cell>
      <Table.Cell className="flex justify-end">
        <ActionIcon
          onClick={() =>
            openModal({
              centered: true,
              size: 'lg',
              children: <ActivityDetailsModal activity={activity} />,
            })
          }
          color="primary"
          className="p-0.5"
          size="xl"
          radius={100}
        >
          <SearchStatusOutline className="w-6 h-6 text-primary-50" />
        </ActionIcon>
      </Table.Cell>
    </Table.Row>
  );
}

function ActivitiesPageRenderer() {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>([null, null]);
  const [page, setPage] = useState(1);

  const { data: activities, isLoading } = useGetActivities({
    page,
    dateRange: selectedDateRange,
  });

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <DatePickerSection selectedDateRange={selectedDateRange} setSelectedDateRange={setSelectedDateRange} />
      <Box className="px-6">
        <Box className="w-full overflow-hidden rounded-xl border border-neutral-80">
          <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
            <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
              <Table.HeadCell className="text-neutral-20 text-p-01-semibold">ID</Table.HeadCell>
              <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Date</Table.HeadCell>
              <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Status</Table.HeadCell>
              <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">View</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y divide-neutral-80 font-poppins">
              {activities?.data &&
                activities?.data?.length > 0 &&
                activities.data.map((activity, index) => (
                  <ActivityRow activity={activity} index={index} key={activity.id} />
                ))}
            </Table.Body>
          </Table>
        </Box>
        {activities?.data && (
          <PaginationControls page={page} pagination={activities?.pagination} onPageChange={setPage} />
        )}
      </Box>
    </Box>
  );
}

export function ActivitiesTab() {
  const { data: activities, isLoading } = useGetActivities({});

  if (isLoading) return <LoadingOverlay visible />;

  if (!activities || activities.data.length === 0) return <EmptyState title="No activities yet" />;

  return <ActivitiesPageRenderer />;
}
