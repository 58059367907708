import { Table, Box, Avatar, Tooltip, ActionIcon, Text } from '@axazara/raiton-atoms';
import { SearchStatusOutline } from '@axazara/raiton-icons';
import { Link } from 'react-router-dom';
import { Earning, formatDate } from 'utilities';
import { EarningStatusBadge } from '../features/Earnings/components/EarningStatusBadge';

export function EarningsTableRow({ earning, withUser }: { earning: Earning; withUser: boolean }) {
  return (
    <Table.Row key={earning.id} className="border-b border-neutral-90">
      <Table.Cell className="text-p-01-medium text-neutral-40">{earning.id}</Table.Cell>
      {withUser && (
        <Table.Cell>
          <Box className="md:flex-row flex flex-col items-center space-x-4">
            <Avatar src={earning.user.profile_photo_url} size={32} />
            <Box>
              <Text className="text-p-01-semibold">
                {earning.user.first_name} {earning.user.last_name}
              </Text>
              <Text className="text-caption-medium text-neutral-40">{earning.user.email}</Text>
            </Box>
          </Box>
        </Table.Cell>
      )}
      <Table.Cell className="text-neutral-40 text-p-01-medium">{earning.amount_formatted}</Table.Cell>
      <Table.Cell>
        <Tooltip label={<Box>{earning.app.name}</Box>}>
          <Avatar src={earning.app.icon_url} size={32} />
        </Tooltip>
      </Table.Cell>
      <Table.Cell>
        <EarningStatusBadge status={earning.status} />
      </Table.Cell>
      <Table.Cell className="text-p-01-medium text-neutral-40">
        {formatDate(earning.created_at, 'en', 'D MMM YYYY')}
      </Table.Cell>
      <Table.Cell className="flex justify-end">
        <Box className="flex space-x-2">
          <ActionIcon
            component={Link}
            to={`/earnings/${earning.id}`}
            color="primary"
            className="p-0.5"
            size="xl"
            radius={100}
          >
            <SearchStatusOutline className="w-6 h-6 text-primary-50" />
          </ActionIcon>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}
