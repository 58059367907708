export const ApiEndpoints = {
  login: '/auth/staff/login',
  staffInvitation: '/auth/staff/invite/:invitationId',
  logout: '/staff/logout',
  profile: '/staff/profile',
  users: '/users',
  staffs: '/staffs/members',

  earnings: '/earnings',
  revokeReasons: '/utils/earning-revocation-reasons',

  creatives: '/creatives',
  socialMedia: '/utils/social-medias',

  currencies: '/utils/currencies',
  activities: '/staffs/activities',
  invitations: '/staffs/invites',

  apps: '/apps',
};
