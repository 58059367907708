import { PaginatedData, PaginatedType } from './pagination';
import { SimplifiedUser } from './user';

export enum StaffPermissions {
  CREATE_STAFFS = 'create.staffs',
  UPDATE_STAFFS = 'update.staffs',
  READ_STAFFS = 'read.staffs',
  READ_APPS = 'read.apps',
  CREATE_APPS = 'create.apps',
  UPDATE_APPS = 'update.apps',
}
export interface Staff {
  id: string;
  full_name: string;
  is_active: boolean;
  first_name: string;
  last_name: string;
  email: string;
  profile_photo_url: string;
  email_verified_at: Date;
  phone: string;
  phone_verified_at: Date;
  is_suspended: boolean;
  role: string;
  suspension_reason: string;
  created_at: string;
  invited_by: SimplifiedUser;
  permissions: StaffPermissions[];
}

export type Staffs = Staff[];

export interface Invitation {
  id: string;
  staff: SimplifiedUser;
  invite_by: SimplifiedUser;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  status: boolean;
  is_accepted: boolean;
  updated_at: string;
  created_at: string;
}

export interface AuthInvite {
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  two_factor_secret: string;
  two_factor_secret_qr_code: string;
  created_at: string;
}

export type Invitations = Invitation[];

export interface Activity {
  staff: Staff;
  id: string;
  data: Record<string, string>;
  action: string;
  updated_at: string;
  created_at: string;
  user_agent: string;
  ip: string;
}
export type Activities = Activity[];

export type PaginatedStaffs = PaginatedType<PaginatedData, 'data', Staffs>;

export type PaginatedInvitations = PaginatedType<PaginatedData, 'data', Invitations>;

export type PaginatedActivities = PaginatedType<PaginatedData, 'data', Activities>;
