import { Box, Pagination, Text } from '@axazara/raiton-atoms';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination as PaginationType } from 'utilities';

interface PaginationControlsProps {
  page: number;
  pagination: PaginationType;
  onPageChange: (page: number) => void;
}

export const PaginationControls: React.FC<PaginationControlsProps> = ({ page, onPageChange, pagination }) => {
  const { t } = useTranslation();

  return (
    <Box className="flex w-full justify-between items-center mt-8 pb-8">
      <Text className="text-p-01-medium text-neutral-60">
        {t('showingResults', {
          from: page * pagination.per_page - pagination.per_page + 1,
          to: pagination.count < pagination.per_page ? page * pagination.count : page * pagination.per_page,
          total: pagination.total,
        })}
      </Text>
      <Pagination
        size="xl"
        radius={0}
        spacing={0}
        color="green"
        page={page}
        initialPage={1}
        total={pagination.last_page ?? 1}
        onChange={onPageChange}
        style={{ gap: 0 }}
        styles={() => ({
          item: {
            fontSize: 14,
            '&:focus': {
              outline: 'none',
            },
          },
        })}
      />
    </Box>
  );
};
