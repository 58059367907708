import { useState, useEffect } from 'react';
import { useMediaQuery } from 'utilities';

export function useAppDetails() {
  const isMobileView = useMediaQuery('(max-width: 800px)');
  const [isSidebarOpened, setIsSidebarOpened] = useState(true);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      const vh = window.innerHeight * 0.01;
      setHeight(vh * 100);
    };

    window.addEventListener('resize', updateHeight);

    updateHeight();
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return {
    isMobileView,
    height,
    isSidebarOpened,
    setIsSidebarOpened,
  };
}
