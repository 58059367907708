import { Box, Text, Button } from '@axazara/raiton-atoms';
import { CopySuccessOutline, CopyOutline } from '@axazara/raiton-icons';
import { openModal } from '@axazara/raiton-molecules';
import { useClipboard } from '@axazara/raiton-utils';
import { CreateApiKey } from './CreateApiKey';

export function AccessKeyTab({ id }: { id: string }) {
  const { copy, copied } = useClipboard({ timeout: 600 });

  return (
    <Box>
      <Box className="space-y-4 mb-12">
        <Box className="space-y-2">
          <Text component="span" className="text-p-01-semibold text-neutral-50">
            App ID
          </Text>
          <Box className="flex justify-between items-center">
            <Text className="text-p-01-semibold flex items-center">{id}</Text>
            <Button
              onClick={() => {
                copy(id);
              }}
              size="xs"
              variant="outline"
              rightIcon={
                copied ? (
                  <CopySuccessOutline className="w-4 h-4 text-primary-40 ml-2" />
                ) : (
                  <CopyOutline className="w-4 h-4 text-primary-40" />
                )
              }
            >
              {copied ? 'copied' : 'copy'}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className="flex justify-center">
        <Button
          onClick={() =>
            openModal({
              size: 'lg',
              centered: true,
              closeOnClickOutside: false,
              children: <CreateApiKey id={id} />,
            })
          }
          size="sm"
        >
          Generate a new app secret
        </Button>
      </Box>
    </Box>
  );
}
