import { useEffect } from 'react';
import { Box, Text, InputField, Button, Alert, OTPInput } from '@axazara/raiton-atoms';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from '@axazara/raiton-molecules';
import { SmsFill, CloseCircleFill } from '@axazara/raiton-icons';
import { useLoginWithOTP } from '@/features';
import { useQueryClient } from '@tanstack/react-query';
import { appPaths } from '@/configs';
import { LoginPayload } from 'utilities';
import { EphemeralStorage, PersistentStorage, StorageKeys } from '@/utils';

export function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const loginWithOTPMutation = useLoginWithOTP();

  const from = ((location.state as any)?.from.pathname as string) || '/';

  const loginWithOTPForm = useForm<LoginPayload>({
    initialValues: { otp_code: '', email: '' },
  });

  useEffect(() => {
    if (PersistentStorage.getData(StorageKeys.PARTNERS_TOKEN_KEY, false)) {
      navigate(appPaths.users.path);
    }
  }, []);

  async function handleLoginWithOTP(values: LoginPayload) {
    try {
      const response = await loginWithOTPMutation.mutateAsync(values);
      EphemeralStorage.setData(StorageKeys.PARTNERS_STORAGE_KEY, (response as any).staff);
      PersistentStorage.setData(StorageKeys.PARTNERS_TOKEN_KEY, (response as any).authorization.token, false);
      queryClient.invalidateQueries();
      navigate(from ?? '/');
    } catch (error) {
      /* empty */
    }
  }

  return (
    <Box>
      <Text component="p" className="text-center text-heading-03-bold mb-8">
        Se connecter
      </Text>
      {loginWithOTPMutation.isError && (
        <Alert withCloseButton color="error.9" icon={<CloseCircleFill />} variant="filled" className="mb-8">
          <Text className="text-p-01-medium">{loginWithOTPMutation.error?.message}</Text>
        </Alert>
      )}
      <form onSubmit={loginWithOTPForm.onSubmit(handleLoginWithOTP)}>
        <InputField
          {...loginWithOTPForm.getInputProps('email')}
          className="mb-8"
          size="sm"
          required
          type="email"
          placeholder="Email address"
          icon={<SmsFill className="w-5 h-5 text-neutral-40" />}
        />
        <Box className="grid justify-center mb-8">
          <Box component="section" className="mx-auto w-max">
            <OTPInput
              size="lg"
              radius={12}
              required
              onChange={(res) => {
                loginWithOTPForm.setFieldValue('otp_code', res);
              }}
            />

            <Text
              to="/auth/reset/reset-two-factor"
              component={Link}
              color="primary.4"
              className="text-p-01 flex text-center mt-2 justify-center w-full"
            >
              Reset the two-factor authentication
            </Text>
          </Box>
        </Box>

        <Button
          loading={loginWithOTPMutation.isLoading}
          disabled={!loginWithOTPForm.isDirty()}
          fullWidth
          type="submit"
          className="text-p-01-semibold"
        >
          Login
        </Button>
      </form>
    </Box>
  );
}
