import { Box, Text, Button, OTPInput } from '@axazara/raiton-atoms';
import { InformationFill } from '@axazara/raiton-icons';
import { closeModal, openModal, useForm } from '@axazara/raiton-molecules';
import { showErrorNotification, showSuccessNotification } from '@/utils';
import { useGenerateApiKeys } from '../services';
import { CopyApiKey } from './CopyApiKey';
import { GenerateApiKeysPayload } from 'utilities';

export function CreateApiKey({ id }: { id: string }) {
  const useGenerateApiKeysMutation = useGenerateApiKeys(id);

  const generateApiKeysForm = useForm<GenerateApiKeysPayload>({
    initialValues: {
      otp_code: '',
    },
  });

  async function handleGenerateKeys(values: GenerateApiKeysPayload) {
    try {
      const response = await useGenerateApiKeysMutation.mutateAsync(values);
      showSuccessNotification((response as any).message);
      closeModal('');
      openModal({
        centered: true,
        children: <CopyApiKey apiKey={response.data.api_key} />,
        size: 'lg',
        title: <Text className="text-heading-05-bold text-neutral-30">Your new API key</Text>,
        padding: 32,
      });
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }
  return (
    <Box className="pb-12 px-12">
      <Box className="flex items-center gap-3 mb-12">
        <InformationFill className="text-critical-50 w-5" />
        <Text className="text-heading-06-semibold text-neutral-30">Are you sure you want to do this action?</Text>
      </Box>
      <Text component="p" className="text-p-02 mb-7">
        You are about to generate a new Token API for this client, enter your OTP code to confirm.
      </Text>
      <Box onSubmit={generateApiKeysForm.onSubmit((values) => handleGenerateKeys(values))} component="form">
        <Box className="mx-auto w-max">
          <OTPInput
            radius={12}
            size="lg"
            required
            inputClassName=""
            onChange={(res) => {
              generateApiKeysForm.setFieldValue('otp_code', res);
            }}
            {...generateApiKeysForm.getInputProps('otp_code')}
            allowedCharacters="numeric"
          />
        </Box>
        <Text component="p" className="text-neutral-60 mb-7 pt-2">
          Enter the 6 digit 6 code from Google Authenticator.
        </Text>
        <Box className="flex gap-4">
          <Button size="xs" onClick={() => closeModal('')} variant="outline">
            Cancel
          </Button>
          <Button
            loading={useGenerateApiKeysMutation.isLoading}
            disabled={!generateApiKeysForm.isDirty()}
            size="xs"
            color="error.3"
            fullWidth
            type="submit"
          >
            Generate a new client secret
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
