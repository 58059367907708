import { getEnvConfigFactory } from '@axazara/raiton-utils';

const currentEnv: string = process.env.VITE_AXAZARA_ENV || 'dev';

const getEnvConfig = getEnvConfigFactory(currentEnv);

export const appConfig = {
  APP_ENV: getEnvConfig({
    dev: 'dev',
    beta: 'beta',
    alpha: 'alpha',
    prod: 'prod',
  }),

  PARTNERS_API_BASE_URL: getEnvConfig({
    dev: 'https://partners-api.axaship.com',
    beta: 'https://partners-api.axaship.com',
    prod: process.env.VITE_PARTNERS_API_BASE_URL,
  }),
};
