import { Navigate, Route, Routes } from 'react-router-dom';
import { appPaths } from '@/configs';
import {
  AddImageCreative,
  AddTextCreative,
  AddVideoCreative,
  AppDetails,
  Apps,
  CreativeDetails,
  Creatives,
  EarningDetails,
  Earnings,
  Invitation,
  Login,
  NotFound,
  StaffDetailsPage,
  Staffs,
  Users,
  UsersDetails,
} from '@/features';
import { PrivateOutlet, AuthLayout } from '@/layouts/AuthLayout';
import { RootLayout } from '@/layouts/RootLayout';

export function AppRoutes() {
  return (
    <Routes>
      <Route path={appPaths.root} element={<PrivateOutlet />}>
        <Route path={appPaths.root} element={<RootLayout />}>
          <Route path={appPaths.root} element={<Navigate to={appPaths.users.path} />} />

          <Route path={appPaths.users.name}>
            <Route index element={<Users />} />
            <Route path=":id" element={<UsersDetails />} />
          </Route>

          <Route path={appPaths.earnings.name}>
            <Route index element={<Earnings />} />
            <Route path=":id" element={<EarningDetails />} />
          </Route>

          <Route path={appPaths.creatives.name}>
            <Route index element={<Creatives />} />
            <Route path=":id" element={<CreativeDetails />} />
          </Route>
          <Route path={appPaths.apps.name}>
            <Route index element={<Apps />} />
            <Route path=":id" element={<AppDetails />} />
          </Route>

          <Route path={appPaths.addImageCreative.path} element={<AddImageCreative />} />
          <Route path={appPaths.addTextCreative.path} element={<AddTextCreative />} />
          <Route path={appPaths.addVideoCreative.path} element={<AddVideoCreative />} />

          <Route path={appPaths.staffs.name} element={<Staffs />} />
          <Route path={appPaths.viewStaff.path} element={<StaffDetailsPage />} />
        </Route>
      </Route>
      <Route path={appPaths.auth} element={<AuthLayout />}>
        <Route path={appPaths.login.name} element={<Login />} index />
      </Route>
      <Route path={appPaths.invitation.path} element={<Invitation />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
