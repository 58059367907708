import * as React from 'react';
import { Profile2userOutline } from '@axazara/raiton-icons';

interface MetaContextType {
  title: string;
  icon: any;
}

interface MetaContextActionType {
  type: 'SET_METADATA';
  payload: {
    title: string;
    icon: any;
  };
}
const defaultMetaContextValue = {
  title: 'Partners',
  icon: Profile2userOutline,
};

const MetaContext = React.createContext<MetaContextType>(defaultMetaContextValue);
export const MetaDispatchContext = React.createContext<React.Dispatch<MetaContextActionType> | null>(null);

export function MetaContextProvider({ children }: React.PropsWithChildren<{}>) {
  const [appData, dispatch] = React.useReducer(appReducer, defaultMetaContextValue);
  return (
    <MetaContext.Provider value={appData}>
      <MetaDispatchContext.Provider value={dispatch}>{children}</MetaDispatchContext.Provider>
    </MetaContext.Provider>
  );
}

function appReducer(state: MetaContextType, action: MetaContextActionType) {
  switch (action.type) {
    case 'SET_METADATA':
      return {
        ...state,
        title: action.payload.title,
        icon: action.payload.icon,
      };
    default:
      return state;
  }
}

export const useMetaContext = () => React.useContext(MetaContext);
export const useMetaDispatchContext = () => React.useContext(MetaDispatchContext);
