import { useLottie } from 'lottie-react';
import { Box } from '@axazara/raiton-atoms';
import animatedLogo from '@/assets/loading_animation.json';

export function Loading({ className = 'w-40 h-40' }: { className: string }) {
  const options = {
    animationData: animatedLogo,
    loop: true,
  };

  const { View } = useLottie(options);

  return <Box className={className}>{View}</Box>;
}
