import { ModalsProvider, NotificationsProvider } from '@axazara/raiton-molecules';
import { RaitonProvider } from '@axazara/raiton-styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AppRoutes } from './routes';
import { MetaContextProvider } from './context/app';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* {process.env.NODE_ENV !== 'prod' && <ReactQueryDevtools initialIsOpen={false} />} */}

      <RaitonProvider
        theme={{
          loader: 'dots',
          fontFamily: 'Poppins, sans-serif',
          components: {
            Tabs: {
              classNames: {
                tab: 'text-p-01-semibold w-full text-neutral-60',
              },
              styles: (theme) => ({
                tabsList: {
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  msOverflowStyle: 'none',
                  scrollbarWidth: 'none',
                  borderBottomWidth: 1,
                  borderColor: theme.colors.neutral[8],
                  flexWrap: 'nowrap',
                  '@media (max-width: 670px)': {
                    overflow: 'auto hidden',
                  },
                },
                tab: {
                  color: theme.colors.neutral[6],
                },
              }),
            },
            InputField: {
              classNames: {
                root: 'font-poppins text-neutral-60 text-p-01',
                input: 'border-neutral-80 rounded-lg',
              },
            },
            DatePickerInput: {
              classNames: {
                root: 'font-poppins text-neutral-60 text-p-01',
                input: 'border-neutral-80 whitespace-nowrap',
              },
              styles: (theme) => ({
                day: {
                  '&[data-in-range]': {
                    color: 'white',
                  },
                  '&[data-weekend]': {
                    color: theme.colors.neutral[2],
                  },
                  '&[data-weekend]&[data-in-range] ': {
                    color: 'white',
                  },
                },
              }),
            },
            PasswordInput: {
              classNames: {
                root: 'font-poppins text-neutral-60 text-p-01',
                input: 'border-neutral-80 rounded-lg',
              },
            },
            Modal: {
              classNames: {
                modal: 'p-8',
              },
            },
          },
        }}
      >
        <MetaContextProvider>
          <ModalsProvider>
            <NotificationsProvider>
              <AppRoutes />
            </NotificationsProvider>
          </ModalsProvider>
        </MetaContextProvider>
      </RaitonProvider>
    </QueryClientProvider>
  );
}
