import { Box } from '@axazara/raiton-atoms';
import { Creative, PaginatedApps } from 'utilities';

import { EditImageCreativeForm } from './EditImageCreativeForm';
import { EditTextCreativeForm } from './EditTextCreativeForm';
import { EditVideoCreativeForm } from './EditVideoCreativeForm';

export default function EditCreativeTab({ creative, apps }: { creative: Creative; apps: PaginatedApps }) {
  return (
    <Box className="px-8 space-y-4 max-w-lg mx-auto mt-8">
      {creative.type === 'image' && <EditImageCreativeForm creative={creative} apps={apps} />}
      {creative.type === 'text' && <EditTextCreativeForm creative={creative} apps={apps} />}
      {creative.type === 'video' && <EditVideoCreativeForm creative={creative} apps={apps} />}
    </Box>
  );
}
