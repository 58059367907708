import React from 'react';
import { Menu, UnstyledButton, Avatar, Text, InputField, Group } from '@axazara/raiton-atoms';
import { ArrowDownOutline, SearchNormal1Outline, CloseCircleFill } from '@axazara/raiton-icons';
import { classNames } from '@axazara/raiton-utils';
import { useFilterDropdownStyles } from '@/utils';
import { App } from 'utilities';

type AppFilterMenuProps = {
  apps: App[] | undefined;
  selectedApp: App | null;
  setSelectedApp: React.Dispatch<React.SetStateAction<App | null>>;
  searchAppQuery: string;
  setSearchAppQuery: React.Dispatch<React.SetStateAction<string>>;
  openedAppFilter: boolean;
  setOpenedAppFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppFilterMenu = ({
  apps,
  selectedApp,
  setSelectedApp,
  searchAppQuery,
  setSearchAppQuery,
  openedAppFilter,
  setOpenedAppFilter,
}: AppFilterMenuProps) => {
  const { classes } = useFilterDropdownStyles({ opened: openedAppFilter });

  return (
    <Menu onOpen={() => setOpenedAppFilter(true)} onClose={() => setOpenedAppFilter(false)} radius="md" withinPortal>
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          {openedAppFilter ? (
            <InputField
              onClick={(e) => e.stopPropagation()}
              classNames={{
                input:
                  'rounded-none !pl-5 !border-0 focus:shadow-none !outline-none focus:border-0 focus-within:border-0 focus-within:border-transparent focus:border-transparent focus:appearance-none min-h-[28px] h-7',
              }}
              value={searchAppQuery}
              className="w-full"
              iconWidth={12}
              size="xs"
              onChange={(e) => setSearchAppQuery(e.target.value)}
              icon={<SearchNormal1Outline className="w-3 h-3" />}
            />
          ) : selectedApp ? (
            <>
              <Group spacing="xs">
                {selectedApp?.icon_url && <Avatar size={24} src={selectedApp?.icon_url} />}
                <Text className={`${classes.label} truncate whitespace-nowrap w-16`}>{selectedApp?.name}</Text>
              </Group>
              {selectedApp && (
                <CloseCircleFill className="w-4 h-4 text-neutral-60" onClick={() => setSelectedApp(null)} />
              )}
            </>
          ) : (
            <Text className="text-footer text-neutral-40">Filter by app</Text>
          )}
          <ArrowDownOutline className={classNames(classes.icon, 'w-4 h-4 text-neutral-40')} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown className="!w-max max-h-80 overflow-y-auto">
        {apps?.map((app) => (
          <Menu.Item
            key={app.id}
            onClick={() => setSelectedApp(app)}
            icon={<Avatar size={24} src={app.icon_url} />}
            className={selectedApp?.name === app.name ? 'bg-neutral-90' : ''}
          >
            {app.name}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
