import { Authorization } from './authorization';
import { BaseEntity } from './base';
import { PaginatedData, PaginatedType } from './pagination';
import { Wallet } from './wallet';

export interface Language {
  symbol: 'en' | 'fr';
  name: string;
  symbol_native: string;
  icon: string;
  icon_url: string;
  code: 'en' | 'fr';
  name_and_icon: string;
}

export interface User extends BaseEntity {
  partner_id: string;
  is_new_user: boolean;
  first_name: string;
  last_name: string;
  email: string;
  profile_photo_url: string;
  email_verified_at: string;
  phone: string;
  phone_verified_at: string;
  is_suspended: boolean;
  identity_is_verified: boolean;
  two_factor_is_enabled: boolean;
  locale: string;
  state: UserAccountState;
  is_active: boolean;
  wallet: Wallet;
  onboarded_at: string;
  last_login_at: string;
  deleted_at: string;
}
export enum UserAccountState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface Account {
  user: User;
  authorization: Authorization;
}

export type SimplifiedUser = Pick<User, 'id' | 'email' | 'first_name' | 'last_name' | 'profile_photo_url'>;

export type Users = User[];

export type PaginatedUsers = PaginatedType<PaginatedData, 'data', Users>;
