import { usePost, useUpdate } from '@/lib';

import { App, GenerateApiKeysPayload, GenerateApiKeysResponse } from 'utilities';
import { ApiEndpoints } from '@/configs';

export const useUpdateApp = (id: string) => useUpdate<Partial<App>, App>(`${ApiEndpoints.apps}/${id}?s=1`);

export const useCreateApp = () => usePost<Partial<App>, App>(ApiEndpoints.apps);

export const useGenerateApiKeys = (id: string) =>
  usePost<GenerateApiKeysPayload, GenerateApiKeysResponse>(`${ApiEndpoints.apps}/${id}/key`);
