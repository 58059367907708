export default {
  'pages.dashboard.paymentStats': 'Total des transactions de paiement',
  'pages.dashboard.payoutStats': 'Total des transactions de transfert',
  'pages.dashboard.customerStats': 'Total des clients',
  'pages.dashboard.recentlyTransactions': 'Transactions récentes',
  'pages.dashboard.recentlyTransactions.description': 'Voici vos transactions de paiement et de transfert récentes',
  'pages.dashboard.paymentsTransactions': 'Transactions de paiement',
  'pages.dashboard.payoutsTransactions': 'Transactions de transfert',

  'pages.transactionRecheck': 'Vérifier à nouveau le statut',
  'pages.transactionRefund': 'Rembourser',
  'pages.transactionRefundTooltip.availableSoon': 'Le remboursement de transaction sera bientôt disponible.',

  'pages.login.title': 'Connectez-vous à votre compte',
  'pages.login.dontHaveAccount': "Vous n'avez pas de compte?",
  'pages.login.createAccount': 'Inscrivez-vous',
  'pages.login.button': 'Se connecter',

  'pages.apps.welcome': 'Bonjour {{name}}, bienvenue à nouveau sur Moneroo',
  'pages.apps.welcome.description':
    'Votre tableau de bord est prêt à être utilisé. Sélectionnez votre application pour commencer.',

  'pages.paymentTransactions.title': 'Transactions de paiement',
  'pages.paymentTransactions.description': 'Transactions de paiement de votre application',

  'pages.payoutTransactions.title': 'Transactions de transfert',
  'pages.payoutTransactions.description': 'Transactions de transfert de votre application',

  'pages.paymentTransactionDetails.title': 'Détails de la transaction de paiement',
  'pages.paymentTransactionDetails.description': 'Transaction: #{{id}}',

  'pages.payoutTransactionDetails.title': 'Détails de la transaction de transfert',
  'pages.payoutTransactionDetails.description': 'Transaction: #{{id}}',

  'pages.paymentMethodsList.availableList': 'Méthodes de paiement disponibles',
  'pages.payoutMethodsList.availableList': 'Méthodes de transfert disponibles',

  'pages.paymentsGateways.title': 'Passerelles de paiement',
  'pages.paymentsGateways.description': 'Gérez vos passerelles de paiement',

  'pages.payoutsGateways.title': 'Passerelles de transfert',
  'pages.payoutsGateways.description': 'Gérez vos passerelles de transfert',

  'pages.paymentGatewayAdd': 'Ajouter une passerelle de paiement',
  'pages.payoutGatewayAdd': 'Ajouter une passerelle de transfert',

  'pages.paymentMethod.title': 'Méthodes de paiement',
  'pages.paymentMethod.description': 'Gérez vos méthodes de paiement',

  'pages.payoutMethod.title': 'Méthodes de transfert',
  'pages.payoutMethod.description': 'Gérez vos méthodes de transfert',

  'pages.dashboard.title': 'Tableau de bord',

  'pages.team.title': 'Équipe',
  'pages.team.description': 'Gérez les membres de votre équipe',

  'pages.developers.title': 'Développeur',
  'pages.developers.description': 'Gérez vos clés API et webhooks',

  'pages.customers.title': 'Clients',
  'pages.customers.app': 'App',
  'pages.customers.description': 'Gérez vos clients',

  'pages.settings.title': 'Paramètres',
  'pages.settings.description': 'Gérez les paramètres de votre application',

  'pages.customerDetails.title': 'Détails du client',
  'pages.customerDetails.description': 'Client #{{ID}}',
};
