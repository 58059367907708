import { appPaths } from '@/configs';
import { AccessKeyTab, AppTab } from '@/features';
import { useSetPageMeta } from '@/hooks';
import { useGetApp } from '@/services';
import { Avatar, Box, Divider, Tabs, Text } from '@axazara/raiton-atoms';
import { CodeOutline, ProfileOutline, ArrowCircleLeftOutline } from '@axazara/raiton-icons';
import { Link, Navigate, useParams } from 'react-router-dom';

export function AppDetails() {
  useSetPageMeta({
    title: 'Apps',
    icon: CodeOutline,
  });

  const { id } = useParams();
  const { data: app } = useGetApp(id ?? '');

  if (!id) return <Navigate to={appPaths.apps.path} />;

  return (
    <Box>
      <Box className="top-0 sticky z-10 bg-white">
        <Box className="flex items-center p-6 space-x-4">
          <Box component={Link} to={appPaths.apps.path}>
            <ArrowCircleLeftOutline className="w-6 h-6 text-neutral-30" />
          </Box>
          <Box className="flex space-x-2 items-center">
            <Avatar src={app?.icon_url} size={64} />
            <Box>
              <Box className="flex flex-col items-center">
                <Text className="text-heading-06-bold mb-1">{app?.name}</Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider className="mb-5 border-neutral-90" />
      </Box>
      <Box className="pt-5">
        <Tabs
          styles={(theme) => ({
            tabsList: {
              borderBottomWidth: 1,
              borderColor: theme.colors.neutral[8],
              flexWrap: 'nowrap',
              '@media (max-width: 670px)': {
                overflow: 'auto hidden',
              },
            },
            tab: {
              color: theme.colors.neutral[6],
            },
          })}
          defaultValue="app"
        >
          <Tabs.List className="max-w-min mx-auto flex justify-between text-p-01-semibold mb-12">
            <Tabs.Tab icon={<CodeOutline className="w-5 h-5 text-primary-4" />} value="app">
              App
            </Tabs.Tab>
            <Tabs.Tab icon={<ProfileOutline className="w-5 h-5 text-primary-4" />} value="access_key">
              Access key
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="app">
            <AppTab />
          </Tabs.Panel>
          <Tabs.Panel value="access_key" className="max-w-lg mx-auto">
            <AccessKeyTab id={id} />
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Box>
  );
}
