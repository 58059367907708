import { Box, Tag, Avatar, Button, Text } from '@axazara/raiton-atoms';
import { SearchStatusOutline } from '@axazara/raiton-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Staff, formatDate } from 'utilities';
import { openModal } from '@axazara/raiton-molecules';
import { ResetStaffOTPConfirmationModal } from './ResetStaffOTPConfirmationModal';

export function StaffInformation({ staff }: { staff: Staff }) {
  const { t, i18n } = useTranslation();
  return (
    <Box className="mb-10 max-w-md mx-auto">
      <Box className="flex justify-between items-center mb-6">
        <Text className="text-p-01-medium">First name:</Text>
        <Text component="span" className="text-p-01">
          {staff.first_name}
        </Text>
      </Box>
      <Box className="flex justify-between items-center mb-6">
        <Text className="text-p-01-medium">Last name:</Text>
        <Text component="span" className="text-p-01">
          {staff.last_name}
        </Text>
      </Box>
      <Box className="flex justify-between items-center mb-6">
        <Text className="text-p-01-medium">Email:</Text>
        <Text component="span" className="text-p-01">
          {staff.email}
        </Text>
      </Box>
      <Box className="flex justify-between items-center mb-6">
        <Text className="text-p-01-medium">Role:</Text>
        <Tag variant="filled" size="lg" className="bg-primary normal-case text-primary-40 text-p-01-semibold">
          {staff.role}
        </Tag>
      </Box>
      <Box className="flex justify-between items-center mb-6">
        <Text className="text-p-01-medium">Added at:</Text>
        <Text component="span" className="text-p-01">
          {formatDate(staff.created_at, i18n.resolvedLanguage, 'D MMM YYYY, HH:mm')}
        </Text>
      </Box>
      {staff.invited_by && (
        <Box>
          <Text className="text-p-01-medium mb-6">Invited by</Text>
          <Box className="flex justify-between gap-10">
            <Box className="flex items-center space-x-4">
              <Avatar src={staff.invited_by?.profile_photo_url} size={48} />
              <Box>
                <Text className="text-p-01-medium mb-2">
                  {staff.invited_by.first_name} {staff.invited_by.last_name}
                </Text>
                <Text className="text-caption-medium text-neutral-40">{staff.invited_by.email}</Text>
              </Box>
            </Box>
            <Button
              size="xs"
              variant="outline"
              rightIcon={<SearchStatusOutline className="w-5 h-5" />}
              component={Link}
              to={`/staffs/${staff.invited_by.id}`}
            >
              {t('view')}
            </Button>
          </Box>
        </Box>
      )}
      <Box className="mt-10 flex justify-end">
        <Button
          color="error"
          size="xs"
          onClick={() =>
            openModal({
              centered: true,
              size: 'lg',
              children: <ResetStaffOTPConfirmationModal staff={staff} />,
            })
          }
        >
          Reset OTP
        </Button>
      </Box>
    </Box>
  );
}
