import { Box, Text, InputField, Select, Button, Textarea, Avatar, ActionIcon } from '@axazara/raiton-atoms';
import { ArrowCircleLeftOutline, GalleryOutline } from '@axazara/raiton-icons';
import { useForm } from '@axazara/raiton-molecules';
import { CreativePayload } from 'utilities';
import { showErrorNotification, showSuccessNotification } from '@/utils';
import { useAddCreative, useGetAllSocialMedia } from '@/features';
import { useNavigate } from 'react-router-dom';
import { useSetPageMeta } from '@/hooks';
import { useGetApps } from '@/services';
import React from 'react';

export function AddTextCreative() {
  useSetPageMeta({
    title: 'Creatives',
    icon: GalleryOutline,
  });
  const { data: apps } = useGetApps({});

  const navigate = useNavigate();

  const languageData = [
    { label: 'EN', value: 'en' },
    { label: 'FR', value: 'fr' },
  ];
  const creativesType = [{ label: 'Text', value: 'text', color: 'bg-secondary-50' }];

  const addCreativesForm = useForm<Partial<CreativePayload>>({
    initialValues: {
      title: '',
      language: '',
      app_id: '',
      social_media: '',
      content: '',
      type: 'text',
    },
  });
  const { data: mediaData } = useGetAllSocialMedia();
  const useAddCreativeMutation = useAddCreative();

  async function handleSubmit(values: Partial<CreativePayload>) {
    try {
      const response = await useAddCreativeMutation.mutateAsync(values);
      addCreativesForm.reset();
      showSuccessNotification((response as any)?.message);
    } catch (error) {
      showErrorNotification((error as any)?.response?.data?.message);
    }
  }
  return (
    <Box className="pt-4 pb-12">
      <Box className="ml-10 pb-5">
        <ActionIcon radius={100} onClick={() => navigate(-1)}>
          <ArrowCircleLeftOutline className="w-6 h-6" />
        </ActionIcon>
      </Box>
      <Box className="px-8 space-y-4 max-w-lg mx-auto">
        <Text className="text-heading-04-sm-bold text-center">Add text</Text>
        <Box component="form" onSubmit={addCreativesForm.onSubmit((values) => handleSubmit(values))}>
          <InputField
            {...addCreativesForm.getInputProps('title')}
            label="Creative name"
            classNames={{
              input: 'rounded-lg',
              label: 'mb-3 text-p-01-semibold text-neutral-40',
            }}
            radius={12}
            placeholder="Creative name"
            size="xs"
            className="w-full mb-5"
          />

          {apps && (
            <Select
              {...addCreativesForm.getInputProps('app_id')}
              placeholder="App"
              label="App"
              classNames={{
                input: 'rounded-lg',
                label: 'mb-3 text-p-01-semibold text-neutral-40',
              }}
              data={apps.data as any}
              optionResolver={(app: any) => ({ label: app.name, value: app.id, icon_url: app.icon_url })}
              type="text"
              size="xs"
              className="mb-5"
              radius={12}
              itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
                <Box className="py-2" ref={ref} key={value} {...others}>
                  <Box className="flex flex-row items-center space-x-2">
                    <Avatar size={32} src={others.icon_url} />
                    <Text className="text-p-01-medium">{label}</Text>
                  </Box>
                </Box>
              ))}
              styles={(theme) => ({
                input: {
                  borderColor: theme.colors.neutral[6],
                },
              })}
            />
          )}

          <Select
            {...addCreativesForm.getInputProps('language')}
            placeholder="Language"
            label="Language"
            classNames={{
              input: 'rounded-lg',
              label: 'mb-3 text-p-01-semibold text-neutral-40',
            }}
            data={languageData as any}
            optionResolver={(language: any) => ({ label: language.label, value: language.value })}
            type="text"
            size="xs"
            className="mb-5"
            radius={12}
            itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
              <Box className="py-2" ref={ref} key={value} {...others}>
                <Box className="flex flex-row items-center ">
                  <Text className="text-p-01-medium">{label}</Text>
                </Box>
              </Box>
            ))}
            styles={(theme) => ({
              input: {
                borderColor: theme.colors.neutral[6],
              },
            })}
          />
          {mediaData && (
            <Select
              {...addCreativesForm.getInputProps('social_media')}
              placeholder="media"
              label="Social media"
              classNames={{
                input: 'rounded-lg',
                label: 'mb-3 text-p-01-semibold text-neutral-40',
              }}
              data={mediaData as any}
              optionResolver={(media: any) => ({ label: media.label, value: media.value, icon_url: media.icon_url })}
              type="text"
              size="xs"
              className="mb-5"
              radius={12}
              itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
                <Box className="py-2" ref={ref} key={value} {...others}>
                  <Box className="flex flex-row items-center space-x-2 ">
                    <Avatar size={32} src={others.icon_url} />
                    <Text className="text-p-01-medium">{label}</Text>
                  </Box>
                </Box>
              ))}
              styles={(theme) => ({
                input: {
                  borderColor: theme.colors.neutral[6],
                },
              })}
            />
          )}

          <Textarea
            {...addCreativesForm.getInputProps('content')}
            label="Description"
            classNames={{
              input: 'rounded-lg',
              label: 'mb-3 text-p-01-semibold text-neutral-40',
            }}
            placeholder="you@example.com"
            className="font-poppins text-p-02"
            minRows={5}
            radius={8}
            autosize
            styles={(theme) => ({
              label: {
                color: theme.colors.neutral[0],
                fontWeight: 600,
                fontSize: 16,
                marginBottom: 8,
              },
              input: {
                borderColor: theme.colors.neutral[7],
                backgroundColor: theme.colors.neutral[9],
                resize: 'vertical',
              },
            })}
          />

          <Select
            {...addCreativesForm.getInputProps('type')}
            placeholder="type"
            data={creativesType as any}
            optionResolver={(type: any) => ({ label: type.label, value: type.value })}
            classNames={{ label: 'text-neutral-50 mb-2 text-p-02' }}
            type="text"
            size="xs"
            className="mb-5 hidden"
            radius={12}
            itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
              <Box className="py-2" ref={ref} key={value} {...others}>
                <Box className="flex flex-row items-center space-x-2 ">
                  <Text className="text-p-01-medium">{label}</Text>
                </Box>
              </Box>
            ))}
          />
          <Box className="text-center pt-6">
            <Button
              type="submit"
              loading={useAddCreativeMutation.isLoading}
              disabled={!addCreativesForm.isDirty()}
              size="xs"
            >
              Add text
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
