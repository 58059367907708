import { EmptyState, PaginationControls } from '@/components';
import { AccessControl } from '@/context';
import { AddApp, AppsTable } from '@/features';
import { useSetPageMeta } from '@/hooks';
import { useGetApps } from '@/services';
import { Box, Button, InputField, LoadingOverlay, Text } from '@axazara/raiton-atoms';
import { CodeOutline, SearchNormal1Outline, CloseCircleFill, AddCircleFill } from '@axazara/raiton-icons';
import { openModal } from '@axazara/raiton-molecules';
import { useDebouncedValue } from '@axazara/raiton-utils';
import React from 'react';
import { StaffPermissions } from 'utilities';

function AppsRendererPage() {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [page, setPage] = React.useState(1);
  const { data: apps, isLoading } = useGetApps({ page, search: debouncedSearch });

  return (
    <Box
      component={AccessControl}
      permissions={[StaffPermissions.CREATE_APPS, StaffPermissions.UPDATE_APPS, StaffPermissions.READ_APPS]}
      isPage
    >
      <Box className="pt-8">
        <LoadingOverlay visible={isLoading} />
        <Box className="flex justify-between space-x-4 mb-8">
          <InputField
            placeholder="Search app"
            size="xs"
            icon={<SearchNormal1Outline className="w-5 h-5" />}
            className="w-full"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            rightSection={
              debouncedSearch ? (
                <CloseCircleFill
                  className="w-4 h-4 text-gray-600"
                  onClick={() => {
                    setSearchQuery('');
                  }}
                />
              ) : null
            }
            classNames={{
              input: 'rounded-xl',
            }}
          />
          <AccessControl permissions={StaffPermissions.CREATE_APPS}>
            <Button
              onClick={() =>
                openModal({
                  centered: true,
                  title: <Text className="text-heading-05-bold text-neutral-30">Add app</Text>,
                  children: <AddApp />,
                  size: 'lg',
                })
              }
              size="xs"
              rightIcon={<AddCircleFill className="w-5 h-5" />}
            >
              Add app
            </Button>
          </AccessControl>
        </Box>
        <Box className="relative">
          <LoadingOverlay visible={isLoading} loaderProps={{ color: 'secondary' }} />
          {apps?.data && (
            <>
              <AppsTable apps={apps.data} />
              <PaginationControls page={page} pagination={apps?.pagination} onPageChange={setPage} />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
export function Apps() {
  useSetPageMeta({
    title: 'Apps',
    icon: CodeOutline,
  });

  const { data: apps, isLoading } = useGetApps({ page: 1 });

  if (isLoading) return <LoadingOverlay visible={isLoading} loaderProps={{ color: 'secondary' }} />;

  if (apps && apps?.data?.length > 0) return <AppsRendererPage />;

  return (
    <EmptyState
      title="No apps yet"
      primaryAction={
        <Button
          onClick={() =>
            openModal({
              centered: true,
              title: <Text className="text-heading-05-bold text-neutral-30">Add app</Text>,
              children: <AddApp />,
              size: 'lg',
            })
          }
          size="xs"
          rightIcon={<AddCircleFill className="w-5 h-5" />}
        >
          Add app
        </Button>
      }
    />
  );
}
