import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { appPaths } from '@/configs';
import { StaffPermissions } from 'utilities';

interface PermissionsProviderProps {
  children: React.ReactNode;
  permissions: StaffPermissions[];
}

type PermissionParams = StaffPermissions | StaffPermissions[];

const PermissionsContext = React.createContext({
  permissions: [] as StaffPermissions[],
  hasPermission: (_permission: PermissionParams) => false as boolean,
});

export const PermissionsProvider = ({ children, permissions }: PermissionsProviderProps) => {
  const hasPermission = (permission: PermissionParams) => {
    const permissionArray = Array.isArray(permission) ? permission : [permission];
    const hasAllPermissions = permissionArray.every((perm) => permissions.includes(perm));

    return hasAllPermissions;
  };

  return <PermissionsContext.Provider value={{ permissions, hasPermission }}>{children}</PermissionsContext.Provider>;
};

export const usePermissions = () => {
  const context = React.useContext(PermissionsContext);

  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }

  return context;
};

interface AccessControlProps {
  permissions: StaffPermissions | StaffPermissions[];
  children: React.ReactNode;
  Fallback?: React.ReactNode;
  isPage?: boolean;
}

export function AccessControl({
  permissions,
  children,
  Fallback = <Navigate to={appPaths.root} />,
  isPage = false,
}: AccessControlProps) {
  const { hasPermission } = usePermissions();
  const isAllowed = hasPermission(permissions);

  if (isAllowed) {
    return <>{children}</>;
  }

  return isPage ? <>{Fallback}</> : null;
}
