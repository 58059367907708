import { Box, Tag, Text } from '@axazara/raiton-atoms';
import { ClockOutline, CloseCircleOutline, TickCircleOutline } from '@axazara/raiton-icons';
import { EarningStatus } from 'utilities';

export function EarningStatusBadge({ status }: { status?: EarningStatus }) {
  if (status === EarningStatus.paid) {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-success border border-success-60 py-3"
        size="lg"
        leftSection={<TickCircleOutline className="w-4 h-4 text-success-30" />}
      >
        <Text component="span" className="text-success-30 text-p-01-semibold">
          Paid
        </Text>
      </Tag>
    );
  }

  if (status === EarningStatus.revoked) {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-critical border border-critical-60 py-3"
        size="lg"
        leftSection={<CloseCircleOutline className="w-4 h-4 text-critical-40" />}
      >
        <Text component="span" className="text-critical-30 text-p-01-semibold">
          Revoked
        </Text>
      </Tag>
    );
  }

  if (status === EarningStatus.pending) {
    return (
      <Tag
        className="text-p-01-semibold normal-case bg-secondary border border-secondary-60 py-3"
        size="lg"
        leftSection={<ClockOutline className="w-4 h-4 text-secondary-40" />}
      >
        <Text component="span" className="text-secondary-40 text-p-01-semibold">
          Pending
        </Text>
      </Tag>
    );
  }
  return <Box />;
}
