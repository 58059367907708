import { Box, Table } from '@axazara/raiton-atoms';
import { Earnings } from 'utilities';
import { EarningsTableRow } from './EarningsTableRow';

export function EarningsTable({ earnings, withUser = false }: { earnings: Earnings; withUser?: boolean }) {
  return (
    <Box className="w-full overflow-hidden rounded-2xl border border-neutral-80">
      <Table className="rounded-xl overflow-hidden border-collapse  border-spacing-0">
        <Table.Head className="text-p-01-semibold border-b border-neutral-80 text-neutral-20 rounded-xl !bg-neutral">
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Reference</Table.HeadCell>
          {withUser && <Table.HeadCell className="text-neutral-20 text-p-01-semibold">User</Table.HeadCell>}
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Amount</Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">App</Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Status</Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold">Date</Table.HeadCell>
          <Table.HeadCell className="text-neutral-20 text-p-01-semibold flex justify-end">View</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-neutral-80">
          {earnings &&
            earnings?.map((earning) => <EarningsTableRow withUser={withUser} earning={earning} key={earning.id} />)}
        </Table.Body>
      </Table>
    </Box>
  );
}
