import { Box, Text } from '@axazara/raiton-atoms';
import { GalleryFill, PlayCircleFill, TextFill } from '@axazara/raiton-icons';
import { closeModal } from '@axazara/raiton-molecules';
import { appPaths } from '@/configs';
import { Link } from 'react-router-dom';

export default function ResourcesType() {
  return (
    <Box className="flex flex-row space-x-6 pb-5 pt-5">
      <Box
        component={Link}
        to={appPaths.addImageCreative.path}
        className="flex flex-col justify-center items-center rounded-xl border border-neutral-80 w-full p-5 hover:text-primary-40"
      >
        <Box onClick={() => closeModal('')}>
          <GalleryFill className="h-12 w-12" />
          <Text className="text-p-01-medium text-center">Image</Text>
        </Box>
      </Box>
      <Box
        component={Link}
        to={appPaths.addVideoCreative.path}
        className="flex flex-col justify-center items-center rounded-xl border border-neutral-80 w-full p-5 hover:text-primary-40"
      >
        <Box onClick={() => closeModal('')}>
          <PlayCircleFill className="h-12 w-12" />
          <Text className="text-p-01-medium text-center">Video</Text>
        </Box>
      </Box>
      <Box
        component={Link}
        to={appPaths.addTextCreative.path}
        onClick={() => closeModal('')}
        className="flex flex-col justify-center items-center rounded-xl border border-neutral-80 w-full p-5"
      >
        <Box className="hover:text-primary-40">
          <TextFill className="h-12 w-12" />
          <Text className="text-p-01-medium text-center">Text</Text>
        </Box>
      </Box>
    </Box>
  );
}
