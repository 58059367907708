import { Box, Tooltip, Text, Surface } from '@axazara/raiton-atoms';
import { useClipboard } from '@axazara/raiton-utils';
import { CopySuccessOutline, CopyOutline } from '@axazara/raiton-icons';

export function CopyableInformationItem({ label, value }: { label: string; value: string }) {
  const { copy, copied } = useClipboard({ timeout: 500 });

  return (
    <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
      <Text className="mb-4 text-neutral-40 text-heading-06-sm-semibold">{label}</Text>
      <Box className="flex items-center justify-between">
        <Text className="text-p-01 text-neutral-40">{value}</Text>
        <Tooltip label={<Text>{copied ? 'copied' : 'copy'}</Text>}>
          <Box onClick={() => copy(value)} className="cursor-pointer">
            {copied ? (
              <CopySuccessOutline className="w-6 h-6 text-primary-40 ml-2" />
            ) : (
              <CopyOutline className="w-6 h-6 text-primary-40 ml-2" />
            )}
          </Box>
        </Tooltip>
      </Box>
    </Surface>
  );
}
