import { Table, Box, Avatar, Tag, ActionIcon, Text } from '@axazara/raiton-atoms';
import { SearchStatusOutline } from '@axazara/raiton-icons';
import { Link } from 'react-router-dom';
import { App } from 'utilities';

export function AppsTableRow({ app }: { app: App }) {
  return (
    <Table.Row key={app.id} className="border-b border-neutral-90">
      <Table.Cell>
        <Box className="flex items-center space-x-1.5 gap-4">
          <Box className="flex justify-center w-14 h-14">
            <Box className="flex items-center p-5">
              <Avatar src={app.icon_url} size={56} />
            </Box>
          </Box>
          <Box>
            <Text className="text-p-02-semibold">{app.name}</Text>
            <Text className="text-p-01 text-neutral-40">ID: {app.id}</Text>
          </Box>
        </Box>
      </Table.Cell>

      <Table.Cell>
        {app.is_public ? (
          <Tag variant="filled" className="normal-case bg-primary-90" size="lg">
            <Text className="text-primary-40 text-p-01-semibold">Public</Text>
          </Tag>
        ) : (
          <Tag variant="filled" className="normal-case bg-secondary-90" size="lg">
            <Text className="text-secondary-40 text-p-01-semibold">Private</Text>
          </Tag>
        )}
      </Table.Cell>
      <Table.Cell className="flex justify-end">
        <Box component={Link} to={`/apps/${app.id}`} className="flex space-x-2">
          <ActionIcon color="primary" className="p-2" size="xl" radius={100}>
            <SearchStatusOutline className="w-6 h-6 text-primary-50" />
          </ActionIcon>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}
