import { UserCard } from '@/components';
import { useMetaContext } from '@/context';
import { Box, Text } from '@axazara/raiton-atoms';

export function Header() {
  const appMetaData = useMetaContext();

  const Icon = appMetaData.icon;

  return (
    <Box className="bg-white rounded-2xl overflow-hidden">
      <Box className="flex flex-row items-center justify-between p-4 px-6">
        <Box>
          <Box className="flex space-x-2">
            <Box className="rounded-full w-8 h-8 md:w-12 md:h-12 bg-primary-90 flex justify-center items-center">
              <Icon className="w-6 text-primary-40" />
            </Box>
            <Box className="md:mt-1 flex-1 flex items-center">
              <Text className="md:text-p-02-semibold text-primary-50 text-heading-06-bold">{appMetaData.title}</Text>
            </Box>
          </Box>
        </Box>
        <Box className="flex flex-row gap-4">
          <UserCard />
        </Box>
      </Box>
    </Box>
  );
}
