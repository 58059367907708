import { Box, Text, Avatar, Tabs, ActionIcon } from '@axazara/raiton-atoms';
import {
  Profile2userFill,
  ArrowCircleLeftOutline,
  TaskSquareOutline,
  LinkCircleOutline,
  MoneyReciveOutline,
} from '@axazara/raiton-icons';

import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useGetUser, useGetUserStats } from '@/features/Users/services';
import { useSetPageMeta } from '@/hooks';
import { appPaths } from '@/configs';
import { EarningsTab, ReferralsLinksTab, UserInformationsTab } from '@/features';

function UsersDetailsPageRenderer({ id }: { id: string }) {
  const navigate = useNavigate();

  const { data: user, isError } = useGetUser(id);

  const { data: userStats } = useGetUserStats(id);

  if (isError) return <Navigate to={appPaths.users.path} />;

  return (
    <Box className="pt-4">
      <Box className="flex items-center gap-6">
        <ActionIcon radius={100} onClick={() => navigate(-1)}>
          <ArrowCircleLeftOutline className="w-6 h-6" />
        </ActionIcon>
        <Box className="md:flex-row flex flex-col items-center space-x-4">
          <Avatar src={user?.profile_photo_url} size={48} />
          <Box>
            <Text className="text-p-01-semibold text-neutral-20 mb-1.5">
              {user?.first_name} {user?.last_name}
            </Text>
            <Text className="text-caption-medium text-neutral-60">@{user?.id}</Text>
          </Box>
        </Box>
      </Box>
      <Box>
        <Tabs
          styles={(theme) => ({
            tabsList: {
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              borderBottomWidth: 1,
              borderColor: theme.colors.neutral[8],
              flexWrap: 'nowrap',
              '@media (max-width: 670px)': {
                overflow: 'auto hidden',
              },
            },
            tab: {
              color: theme.colors.neutral[6],
            },
          })}
          defaultValue="information"
        >
          <Box className="justify-center">
            <Tabs.List className="mb-12 max-w-4xl mx-auto">
              <Tabs.Tab
                className="md:text-p-02-semibold text-p-01-semibold w-full"
                value="information"
                icon={<TaskSquareOutline className="w-6 h-6" />}
              >
                Informations
              </Tabs.Tab>
              <Tabs.Tab
                className="md:text-p-02-semibold text-p-01-semibold w-full"
                value="earnings"
                icon={<MoneyReciveOutline className="w-6 h-6" />}
              >
                Earnings
              </Tabs.Tab>

              <Tabs.Tab
                className="md:text-p-02-semibold text-p-01-semibold w-full"
                value="referrals-links"
                icon={<LinkCircleOutline className="w-6 h-6" />}
              >
                Referrals links
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="information">
              {user && userStats && <UserInformationsTab userInformations={user} userStats={userStats} />}
            </Tabs.Panel>
            <Tabs.Panel value="earnings">
              <EarningsTab id={id} />
            </Tabs.Panel>
            <Tabs.Panel value="referrals-links">
              <ReferralsLinksTab id={id} />
            </Tabs.Panel>
          </Box>
        </Tabs>
      </Box>
    </Box>
  );
}

export function UsersDetails() {
  useSetPageMeta({
    title: 'Users',
    icon: Profile2userFill,
  });

  const { id } = useParams();
  if (!id) {
    return <Navigate to={appPaths.users.path} />;
  }

  return <UsersDetailsPageRenderer id={id} />;
}
