import { Box, Button, OTPInput, Select, Text, Textarea } from '@axazara/raiton-atoms';
import { closeModal, useForm } from '@axazara/raiton-molecules';
import { showErrorNotification, showSuccessNotification } from '@/utils';
import { Earning, RevokeEarningPayload } from 'utilities';
import { useGetRevokeEarningReasons, useRevokeEarning } from '@/features';

export function RejectEarningsTransactions({ earning }: { earning: Earning }) {
  const revokedEarningStatusMutation = useRevokeEarning({ id: earning.id });
  const { data: reasons } = useGetRevokeEarningReasons();
  const revokedEarningStatusForm = useForm<RevokeEarningPayload>({
    initialValues: {
      reason: '',
      internal_note: '',
      otp_code: '',
    },
  });

  async function handleRevokedEarningStatus(params: RevokeEarningPayload) {
    try {
      const response = await revokedEarningStatusMutation.mutateAsync(params);
      showSuccessNotification((response as any)?.message);
      closeModal('');
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  return (
    <Box component="form" onSubmit={revokedEarningStatusForm.onSubmit(handleRevokedEarningStatus)} className="w-full">
      {reasons && (
        <Select
          {...revokedEarningStatusForm.getInputProps('reason')}
          placeholder="reasons"
          data={reasons as any}
          optionResolver={(reason: any) => ({ label: reason.label, value: reason.value })}
          classNames={{ label: 'text-neutral-50 mb-2 text-p-02' }}
          type="text"
          size="sm"
          className="mb-5"
          radius={12}
          itemComponent={({ label, value, ...others }) => (
            <Box className="py-2" key={value} {...others}>
              <Box className="flex flex-row items-center space-x-2">
                <Text className="text-p-01-medium">{label}</Text>
              </Box>
            </Box>
          )}
          styles={(theme) => ({
            input: {
              borderColor: theme.colors.neutral[6],
            },
          })}
        />
      )}
      <Textarea
        {...revokedEarningStatusForm.getInputProps('internal_note')}
        placeholder="internal note"
        className="font-poppins text-p-02"
        minRows={5}
        radius={8}
        autosize
        styles={(theme) => ({
          label: {
            color: theme.colors.neutral[0],
            fontWeight: 600,
            fontSize: 16,
            marginBottom: 8,
          },
          input: {
            borderColor: theme.colors.neutral[7],
            backgroundColor: theme.colors.neutral[9],
            resize: 'vertical',
          },
        })}
      />

      <Text className="text-center mt-8 text-heading-05-bold mb-4">Add OTP code</Text>
      <Box className="flex flex-col justify-center items-center">
        <OTPInput
          {...revokedEarningStatusForm.getInputProps('otp_code')}
          length={6}
          onChange={(res) => {
            revokedEarningStatusForm.setFieldValue('otp_code', res);
          }}
          radius={12}
          size="lg"
          allowedCharacters="numeric"
        />
        <Button
          loading={revokedEarningStatusMutation.isLoading}
          disabled={!revokedEarningStatusForm.isDirty()}
          type="submit"
          fullWidth
          color="error.3"
          className="text-p-01-semibold mt-8"
        >
          Rejected earning
        </Button>
      </Box>
    </Box>
  );
}
