import { Currency, Earning, formatDate, PaginatedEarnings, RevokeEarningPayload, RevokeReasons } from 'utilities';
import { DateRange } from '@/types';
import { buildFetchParams } from '@axazara/raiton-utils';
import { ApiEndpoints } from '@/configs';
import { useFetch, usePost } from '@/lib';

export const useGetEarnings = ({
  page = 1,
  search,
  initialData,
  dateRange = [null, null],
  status = '',
  currency = '',
  app_id = '',
}: {
  page?: number;
  search?: string;
  status?: string;
  initialData?: PaginatedEarnings;
  dateRange?: DateRange;
  currency?: string;
  app_id?: string;
}) => {
  const [fromDate, toDate] = dateRange;
  let params = buildFetchParams({
    page,
    search,
    status: status !== 'all' ? status : '',
    currency,
    app_id,
    from: fromDate && toDate && formatDate(fromDate.toDateString(), undefined, 'YYYY-MM-DD'),
    to: fromDate && toDate && formatDate(toDate.toDateString(), undefined, 'YYYY-MM-DD'),
  });

  return useFetch<PaginatedEarnings>(ApiEndpoints.earnings, params, { initialData });
};

export const useGetAllCurrencies = () => useFetch<Currency[]>(ApiEndpoints.currencies);

export const useGetEarningDetails = ({ id }: { id: string }) => useFetch<Earning>(`${ApiEndpoints.earnings}/${id}`);

export const useRevokeEarning = ({ id }: { id: string }) =>
  usePost<RevokeEarningPayload, { message: string }>(`${ApiEndpoints.earnings}/${id}/revoke`);

export const useGetRevokeEarningReasons = () => useFetch<RevokeReasons>(ApiEndpoints.revokeReasons);
