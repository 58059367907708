import { Outlet } from 'react-router-dom';
import { Box, Surface } from '@axazara/raiton-atoms';

export function AuthLayout() {
  return (
    <Box
      component="main"
      className="bg-neutral min-h-screen w-full overflow-y-auto flex flex-col items-center justify-center place-content-center py-16 px-4"
    >
      <Box className="flex justify-center mb-6 md:mb-16">
        <Box
          component="img"
          src="https://cdn.axazara.com/brand/partners/logo.svg"
          alt="zeyow"
          className="md:w-52 md:h-12 w-28 h-6"
        />
      </Box>
      <Surface radius={16} className="max-w-2xl mx-auto px-4 py-8 md:p-16 w-full">
        <Outlet />
      </Surface>
    </Box>
  );
}
