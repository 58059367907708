import { buildFetchParams } from '@axazara/raiton-utils';
import { DateRange } from '@/types';
import {
  Currency,
  PaginatedEarnings,
  PaginatedReferralLinks,
  PaginatedUsers,
  StatsSummary,
  User,
  formatDate,
} from 'utilities';
import { useFetch } from '@/lib';
import { ApiEndpoints } from '@/configs';

export const useGetUsers = ({
  page,
  search,
  initialData,
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedUsers;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedUsers>(ApiEndpoints.users, params, { initialData });
};

export const useGetUser = (id: string) => useFetch<User>(`${ApiEndpoints.users}/${id}`);

export const useGetUserStats = (id: string) => useFetch<StatsSummary>(`${ApiEndpoints.users}/${id}/stats`);

export const useGetUserEarnings = (
  id: string,
  {
    page,
    search,
    initialData,
    dateRange = [null, null],
    status = '',
    currency = '',
    app_id = '',
  }: {
    page?: number;
    search?: string;
    status?: string;
    initialData?: PaginatedEarnings;
    dateRange?: DateRange;
    currency?: string;
    app_id?: string;
  }
) => {
  const [fromDate, toDate] = dateRange;
  const params = buildFetchParams({
    from: fromDate && toDate && formatDate(fromDate.toDateString(), undefined, 'YYYY-MM-DD'),
    to: fromDate && toDate && formatDate(toDate.toDateString(), undefined, 'YYYY-MM-DD'),
    page,
    search,
    status: status !== 'all' ? status : '',
    currency,
    app_id,
  });

  return useFetch<PaginatedEarnings>(`${ApiEndpoints.users}/${id}/earnings`, params, { initialData });
};

export const useGetCurrencies = () => useFetch<Currency[]>(ApiEndpoints.currencies);

export const useGetUserReferralLinks = (
  id: string,
  {
    page,
    search,
    initialData,
    dateRange = [null, null],
  }: {
    page?: number;
    search?: string;
    initialData?: PaginatedReferralLinks;
    dateRange?: DateRange;
  }
) => {
  const [fromDate, toDate] = dateRange;
  const params = buildFetchParams({
    from: fromDate && toDate && formatDate(fromDate.toDateString(), undefined, 'YYYY-MM-DD'),
    to: fromDate && toDate && formatDate(toDate.toDateString(), undefined, 'YYYY-MM-DD'),
    page,
    search,
  });

  return useFetch<PaginatedReferralLinks>(`${ApiEndpoints.users}/${id}/referral-links`, params, { initialData });
};
