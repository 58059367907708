import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axios } from './axios';

class ApiHelper {
  api: AxiosInstance;

  constructor() {
    this.api = axios;
  }

  async get<T>(url: string, config?: AxiosRequestConfig) {
    return this.api.get<T>(url, config);
  }

  async post<T>(url: string, data?: unknown, config?: AxiosRequestConfig) {
    return this.api.post<T>(url, data, config);
  }

  async put<T>(url: string, data: unknown, config?: AxiosRequestConfig) {
    return this.api.put<T>(url, data, config);
  }

  async patch<T>(url: string, data: unknown, config?: AxiosRequestConfig) {
    return this.api.patch<T>(url, data, config);
  }

  async delete<T>(url: string, config?: AxiosRequestConfig) {
    return this.api.delete<T>(url, config);
  }
}

export const Api = new ApiHelper();
