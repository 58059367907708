import { SimplifiedApp } from './app';
import { Currency } from './currency';
import { PaginatedData, PaginatedType } from './pagination';
import { User } from './user';
import { BaseEntity } from './base';

export interface Earning extends BaseEntity {
  amount: number;
  amount_formatted: string;
  currency: Currency;
  status: EarningStatus;
  order_id: string;
  object: string;
  revocation_reason: string;
  revocation_internal_note: string;
  referral_id: string;
  user: Pick<User, 'id' | 'first_name' | 'last_name' | 'email' | 'profile_photo_url' | 'identity_is_verified'>;
  revoked_by_staff: Pick<User, 'id' | 'first_name' | 'last_name' | 'email' | 'profile_photo_url'>;
  app: SimplifiedApp;
  metadata: Record<string, string>;
  due_at: string;
  paid_at: string;
  revoked_at: string;
}

export type Earnings = Earning[];

export type PaginatedEarnings = PaginatedType<PaginatedData, 'data', Earnings>;

export enum EarningStatus {
  all = 'all',
  pending = 'pending',
  paid = 'paid',
  revoked = 'revoked',
}

export interface RevokeEarningPayload {
  reason: string;
  internal_note: string;
  otp_code: string;
}

export interface RevokeReason {
  label: string;
  value: string;
}

export type RevokeReasons = RevokeReason[];
