import { useMetaDispatchContext } from '@/context';
import { useEffect } from 'react';

export function useSetPageMeta({ title, icon }: { title: string; icon: any }) {
  const metaDispatchContext = useMetaDispatchContext();

  useEffect(() => {
    metaDispatchContext!({
      type: 'SET_METADATA',
      payload: {
        title,
        icon,
      },
    });
  }, [title, icon]);
}
