export const appPaths = {
  root: '/',
  auth: '/auth',
  login: { path: '/auth/login', name: 'login' },
  invitation: { path: '/auth/invitation/:token', name: 'invitation' },

  users: { path: '/users', name: 'users' },
  usersDetails: { path: '/users/:id', name: 'users-details' },

  earnings: { path: '/earnings', name: 'earnings' },
  earningDetails: { path: '/earnings/:id', name: 'earnings' },

  creatives: { path: '/creatives', name: 'creatives' },
  creativeDetails: { path: '/creatives/:id', name: 'creatives' },

  apps: { path: '/apps', name: 'apps' },
  appDetails: { path: '/apps/:id', name: 'apps' },

  addTextCreative: { path: '/creatives/add/text', name: 'creatives' },
  addImageCreative: { path: '/creatives/add/image', name: 'creatives' },
  addVideoCreative: { path: '/creatives/add/video', name: 'creatives' },

  staffs: { path: '/staffs', name: 'staffs' },
  viewStaff: { path: '/staffs/:id', name: 'staff-view' },
};
