import { Button } from '@axazara/raiton-atoms';
import { useTranslation } from 'react-i18next';

export function ErrorFallbackUI() {
  const { t } = useTranslation();
  return (
    <div className="h-full w-full items-center justify-center flex">
      <div className="w-full flex items-center justify-center flex-col space-y-3">
        <div className="text-2xl font-semibold"> 😱 {t('component.unknownError')}</div>
        <Button type="button" onClick={() => window.location.reload()}>
          Reload
        </Button>
      </div>
    </div>
  );
}
