import { EarningsTable, EmptyState, PaginationControls, TransactionsFilter } from '@/components';
import { Box, LoadingOverlay } from '@axazara/raiton-atoms';
import { MoneyReciveOutline } from '@axazara/raiton-icons';
import { useGetEarnings } from '../services';
import { useEarnings } from './useEarnings';
import { useSetPageMeta } from '@/hooks';

function EarningsPageRenderer() {
  const {
    // Data and loading states
    earnings,
    isLoading,
    apps,

    // Search states and setters
    setSearchQuery,
    setSearchAppQuery,

    debouncedSearch,
    searchQuery,
    searchAppQuery,

    // Filter states and setters
    setSelectedStatus,
    setSelectedApp,
    setSelectedDateRange,

    // Dropdown open states and setters
    setOpenedStatusFilter,
    setOpenedAppFilter,

    // Pagination
    page,
    setPage,

    // URL Params
    setUrlParams,

    // Static and selected filter states
    statusData,
    selectedApp,
    openedStatusFilter,
    openedAppFilter,
    selectedStatus,
    selectedDateRange,

    currencies,
    selectedCurrency,
    setSelectedCurrency,
  } = useEarnings();

  return (
    <Box className="py-5">
      <LoadingOverlay visible={isLoading} loaderProps={{ color: 'secondary' }} />
      <Box>
        <Box className="mb-4">
          <TransactionsFilter
            currencies={currencies}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            debouncedSearch={debouncedSearch}
            searchAppQuery={searchAppQuery}
            setSearchAppQuery={setSearchAppQuery}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            statusData={statusData}
            openedStatusFilter={openedStatusFilter}
            setOpenedStatusFilter={setOpenedStatusFilter}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            apps={apps?.data}
            selectedApp={selectedApp}
            setSelectedApp={setSelectedApp}
            openedAppFilter={openedAppFilter}
            setOpenedAppFilter={setOpenedAppFilter}
            setUrlParams={setUrlParams}
          />
        </Box>
        <Box className="relative">
          <LoadingOverlay visible={isLoading} loaderProps={{ color: 'secondary' }} />
          {earnings?.data && (
            <>
              <EarningsTable earnings={earnings.data} withUser />
              <PaginationControls page={page} pagination={earnings?.pagination} onPageChange={setPage} />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
export function Earnings() {
  useSetPageMeta({
    title: 'Earnings',
    icon: MoneyReciveOutline,
  });

  const { data: earnings, isLoading } = useGetEarnings({ page: 1 });

  if (isLoading) return <LoadingOverlay visible />;

  if (earnings?.data && earnings.data.length > 0) return <EarningsPageRenderer />;

  return <EmptyState title="No earnings yet" />;
}
