import { Box, Button, InputField, Select, Text } from '@axazara/raiton-atoms';
import { FrameFill, GlobalFill } from '@axazara/raiton-icons';
import { useForm, closeModal } from '@axazara/raiton-molecules';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateApp } from '../services';
import { showErrorNotification, showSuccessNotification } from '@/utils';
import { App } from 'utilities';
import React from 'react';
import { generateCommissionData } from './CommissionData';

export function AddApp() {
  const queryClient = useQueryClient();
  const commissionData = generateCommissionData(30);

  const appAddForm = useForm<Partial<App>>({
    initialValues: {
      name: '',
      website_url: '',
      destination_url: '',
      commission_due_days: undefined,
    },
  });

  const addAppMutation = useCreateApp();

  async function handleAddAuth(values: Partial<App>) {
    try {
      const response = await addAppMutation.mutateAsync(values);
      showSuccessNotification((response as any)?.message);
      queryClient.invalidateQueries();
      closeModal('');
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  return (
    <Box component="form" onSubmit={appAddForm.onSubmit(handleAddAuth)} className="space-y-8">
      <InputField
        required
        {...appAddForm.getInputProps('name')}
        size="xs"
        label="Name"
        icon={<FrameFill className="w-5 h-5 text-neutral-40" />}
      />
      <InputField
        required
        {...appAddForm.getInputProps('website_url')}
        type="url"
        size="xs"
        label="Website URL"
        icon={<GlobalFill className="w-5 h-5 text-neutral-40" />}
      />

      <InputField
        required
        {...appAddForm.getInputProps('destination_url')}
        type="url"
        size="xs"
        label="Destination URL"
        icon={<GlobalFill className="w-5 h-5 text-neutral-40" />}
      />
      {commissionData && (
        <Select
          label="Commission due days"
          {...appAddForm.getInputProps('commission_due_days')}
          placeholder="select commission due days"
          data={commissionData as any}
          optionResolver={(percent: any) => ({
            label: percent.label,
            value: percent.value,
          })}
          classNames={{ label: 'text-neutral-40' }}
          type="text"
          size="xs"
          className="mb-5"
          radius={12}
          itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
            <Box className="py-2" ref={ref} key={value} {...others}>
              <Box className="flex flex-row items-center ">
                <Text className="text-p-01-medium">{label}</Text>
              </Box>
            </Box>
          ))}
          styles={(theme) => ({
            input: {
              borderColor: theme.colors.neutral[6],
            },
          })}
        />
      )}
      <Box className="flex justify-end">
        <Button
          size="xs"
          type="submit"
          disabled={!appAddForm.isDirty()}
          loading={addAppMutation.isLoading}
          color="success.3"
        >
          Add client
        </Button>
      </Box>
    </Box>
  );
}
