import { ApiEndpoints } from '@/configs';
import { useFetch } from '@/lib';
import { buildFetchParams } from '@axazara/raiton-utils';
import { App, PaginatedApps } from 'utilities';

export const useGetApps = (
  { page, search }: { page?: number; search?: string } = {
    page: 1,
    search: '',
  }
) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedApps>(ApiEndpoints.apps, params);
};

export const useGetApp = (id: string) => useFetch<App>(`${ApiEndpoints.apps}/${id}`);
