import { ApiEndpoints } from '@/configs';
import { useFetch, usePost } from '@/lib';
import { LoginPayload, LoginResponse, AuthInvite } from 'utilities';

export const useLoginWithOTP = () => usePost<LoginPayload, LoginResponse>(ApiEndpoints.login);

export const useGetInvitation = (inviteCode: string) => useFetch<AuthInvite>(`/auth/staff/invite/${inviteCode}`);

export const useAcceptInvitation = (inviteCode: string) =>
  usePost<{ otp_code: string }, { message: string }>(`/auth/staff/invite/${inviteCode}`);

export const useGetOTPReset = (token: string) => useFetch<AuthInvite>(`/auth/staff/reset-two-factor/${token}`);

export const useResetOTP = (token: string) =>
  usePost<{ otp_code: string }, { message: string }>(`/auth/staff/reset-two-factor/${token}`);
