import { useState, useEffect } from 'react';
import { useDynamicURLSearchParams } from '@/hooks';
import { App, formatDate } from 'utilities';
import { DateRange } from '@/types';
import { useDebouncedValue, buildFetchParams } from '@axazara/raiton-utils';
import { StatusType } from '@/components';
import { useGetAllCurrencies, useGetEarnings } from '@/features';
import { useGetApps } from '@/services';

export function useEarnings() {
  // Contexts and translations

  // Static data
  const statusData = [
    { label: 'all', value: 'all', color: 'bg-neutral-50' },
    { label: 'paid', value: 'paid', color: 'bg-success-50' },
    { label: 'revoked', value: 'revoked', color: 'bg-critical-50' },
    { label: 'pending', value: 'pending', color: 'bg-secondary-50' },
  ];

  const { params: urlParams, setParams: setUrlParams } = useDynamicURLSearchParams<{
    search: string;
    currency: string;
    page: string;
    status: string;
    from: string;
    to: string;
  }>({ search: '', page: '1', status: 'all', from: '', to: '', currency: '' });

  // State for filter dropdowns
  const [openedStatusFilter, setOpenedStatusFilter] = useState(false);
  const [openedAppFilter, setOpenedAppFilter] = useState(false);

  // State for search queries
  const [searchQuery, setSearchQuery] = useState(urlParams.search);
  const [searchAppQuery, setSearchAppQuery] = useState('');

  // Debounced search queries
  const [debouncedSearch] = useDebouncedValue(searchQuery, 500);
  const [debouncedAppSearch] = useDebouncedValue(searchAppQuery, 500);

  // State for selected filters
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>([null, null]);
  const [selectedStatus, setSelectedStatus] = useState<StatusType | null>(
    statusData.find((status) => status.value === urlParams.status) || statusData[0]
  );
  const [selectedApp, setSelectedApp] = useState<App | null>(null);
  const [page, setPage] = useState(1);

  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(urlParams.currency);

  // Fetching data
  const { data: apps } = useGetApps({ page: 1, search: debouncedAppSearch });

  const { data: currencies } = useGetAllCurrencies();

  const { data: earnings, isLoading } = useGetEarnings({
    page,
    search: debouncedSearch,
    currency: selectedCurrency ?? '',
    status: selectedStatus?.value || 'all',
    app_id: selectedApp?.id,
    dateRange: selectedDateRange,
  });

  useEffect(() => {
    const [fromDate, toDate] = selectedDateRange;

    const formattedParams = buildFetchParams({
      search: debouncedSearch,
      currency: selectedCurrency ?? '',
      status: selectedStatus?.value,
      page: page.toString(),
      from: fromDate && toDate ? formatDate(fromDate.toDateString(), undefined, 'YYYY-MM-DD') : '',
      to: toDate && fromDate ? formatDate(toDate.toDateString(), undefined, 'YYYY-MM-DD') : '',
    });

    setUrlParams(formattedParams);

    if (!fromDate || !toDate) setUrlParams({ to: '', from: '' });
  }, [debouncedSearch, selectedStatus, page, selectedDateRange]);

  return {
    // Data and loading states
    earnings,
    isLoading,
    apps,

    // Search states and setters
    setSearchQuery,
    setSearchAppQuery,

    debouncedSearch,
    searchQuery,
    searchAppQuery,
    debouncedAppSearch,

    // Filter states and setters
    setSelectedStatus,
    setSelectedApp,
    setSelectedDateRange,

    // Dropdown open states and setters
    setOpenedStatusFilter,
    setOpenedAppFilter,

    // Pagination
    page,
    setPage,

    // URL Params
    urlParams,
    setUrlParams,

    // Static and selected filter states
    statusData,
    selectedApp,
    openedStatusFilter,
    openedAppFilter,
    selectedStatus,
    selectedDateRange,

    currencies,
    selectedCurrency,
    setSelectedCurrency,
  };
}
