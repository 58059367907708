import { appPaths } from '@/configs';
import { Box, Surface, Text } from '@axazara/raiton-atoms';
import { Navigate } from 'react-router-dom';
import { Creative, CreativeType, formatDate } from 'utilities';
import { CreativeImageItem } from './CreativeImageItem';
import { CreativeTextItem } from './CreativeTextItem';
import { CreativeVideoItem } from './CreativeVideoItem';

export default function CreativeInformationsTab({ creative }: { creative: Creative }) {
  return (
    <Box className="max-w-2xl mx-auto space-y-4 mb-20 mt-8">
      <Surface radius={24} className="border border-neutral-80 p-6">
        <Box className="flex flex-row mb-4">
          <Text className="w-40 text-p-01-medium text-neutral-50">Name:</Text>
          <Text className="text-neutral-30 text-p-01">{creative?.title}</Text>
        </Box>
        <Box className="flex flex-row mb-4">
          <Text className="w-40 text-p-01-medium text-neutral-50">App:</Text>
          <Text className="text-neutral-30 text-p-01">{creative?.app?.name}</Text>
        </Box>
        <Box className="flex flex-row mb-4">
          <Text className="w-40 text-p-01-medium text-neutral-50">Date:</Text>
          <Text className="text-neutral-30 text-p-01">
            {creative?.created_at && formatDate(creative?.created_at, 'en', 'D MMM YYYY, HH:mm')}
          </Text>
        </Box>
        <Box className="flex flex-row">
          <Text className="w-40 text-p-01-medium text-neutral-50">Language:</Text>
          <Text className="text-neutral-30 text-p-01">{creative?.language}</Text>
        </Box>
      </Surface>

      {{
        [CreativeType.image]: <CreativeImageItem creative={creative} />,
        [CreativeType.text]: <CreativeTextItem creative={creative} />,
        [CreativeType.video]: <CreativeVideoItem creative={creative} />,
      }[creative.type] || <Navigate to={appPaths.creatives.path} />}
    </Box>
  );
}
