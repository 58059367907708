import {
  Box,
  Text,
  Button,
  InputField,
  Switch,
  Textarea,
  Divider,
  LoadingOverlay,
  Select,
} from '@axazara/raiton-atoms';

import { BoxFill, AddOutline, ImageFill } from '@axazara/raiton-icons';
import { useForm } from '@axazara/raiton-molecules';
import { useUpdateApp } from '../services';
import { showErrorNotification, showSuccessNotification } from '@/utils';
import { AccessControl } from '@/context';
import { useGetApp } from '@/services';
import { App, StaffPermissions } from 'utilities';
import { Navigate, useParams } from 'react-router-dom';
import { appPaths } from '@/configs';
import { generateCommissionData } from './CommissionData';
import React from 'react';

export function AppTabRenderer({ id }: { id: string }) {
  const commissionData = generateCommissionData(30);

  const { data: app, isLoading, refetch } = useGetApp(id);

  const appDataForm = useForm<Partial<App>>({
    initialValues: {
      name: app?.name,
      website_url: app?.website_url,
      allowed_ips: app?.allowed_ips,
      icon_url: app?.icon_url,
      is_public: app?.is_public,
      commission_due_days: app?.commission_due_days,
    },
  });

  const useUpdateAppMutation = useUpdateApp(id as string);

  async function handleSubmit(values: Partial<App>) {
    try {
      const response = await useUpdateAppMutation.mutateAsync(values);
      showSuccessNotification((response as any)?.message);
    } catch (error) {
      showErrorNotification((error as any)?.message);
    }
  }

  async function handleSwitchVisibility(value: boolean) {
    try {
      const response = await useUpdateAppMutation.mutateAsync({ is_public: value });
      if (refetch) refetch();
      showSuccessNotification((response as any).message);
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Box className="max-w-xl mx-auto my-5">
        <Box className="flex justify-between">
          <Box className="flex items-center gap-6">
            <Text className="text-neutral-20 text-p-01-semibold">Visibility</Text>
            <Switch
              {...appDataForm.getInputProps('is_public')}
              styles={(theme) => ({
                track: {
                  minWidth: 52,
                  borderRadius: 24,
                  backgroundColor: theme.colors.neutral[8],
                  border: 0,
                },
                root: {
                  width: 52,
                },
                thumb: {
                  border: 'none',
                  left: 4,
                  'input:checked + * > &': {
                    left: 'calc(100% - 32px)',
                  },
                },
              })}
              checked={app?.is_public}
              onChange={({ currentTarget }) => handleSwitchVisibility(currentTarget.checked)}
              thumbIcon={
                app?.is_public ? (
                  <Box>
                    <img src="/images/mark.svg" alt="mark_icons" />
                  </Box>
                ) : (
                  <Box style={{ transform: 'rotate(45deg)' }}>
                    <AddOutline className=" text-neutral-30 w-6 h-6" />
                  </Box>
                )
              }
              size="xl"
            />
          </Box>
        </Box>
        <Divider className="my-5" color="neutral.8" />
      </Box>
      <Box className="max-w-xl mx-auto mt-6">
        <Box onSubmit={appDataForm.onSubmit(handleSubmit)} component="form" action="">
          <Box className="space-y-6 mb-12">
            <InputField
              label="App name"
              {...appDataForm.getInputProps('name')}
              defaultValue={app?.name}
              size="sm"
              icon={<BoxFill className="w-6 h-6 text-neutral-20" />}
              classNames={{
                input: 'bg-neutral rounded-lg',
              }}
              styles={(theme) => ({
                label: {
                  marginBottom: 8,
                  color: theme.colors.neutral[4],
                  fontWeight: 400,
                  fontSize: 16,
                },
              })}
            />

            {app && (
              <Select
                defaultValue={app?.commission_due_days.toString()}
                {...appDataForm.getInputProps('commission_due_days')}
                label="Commission due days"
                classNames={{
                  input: 'rounded-lg',
                  label: 'mb-3 text-p-01 text-neutral-40',
                }}
                placeholder="commission due days"
                data={commissionData as any}
                optionResolver={(type: any) => ({
                  label: type.label,
                  value: type.value,
                })}
                size="xs"
                className="mb-5"
                radius={12}
                itemComponent={React.forwardRef(({ label, value, ...others }, ref) => (
                  <Box className="py-2" ref={ref} key={value} {...others}>
                    <Box className="flex flex-row items-center ">
                      <Text className="text-p-01-medium">{label}</Text>
                    </Box>
                  </Box>
                ))}
                styles={(theme) => ({
                  input: {
                    borderColor: theme.colors.neutral[6],
                  },
                })}
              />
            )}
            <InputField
              required
              {...appDataForm.getInputProps('icon_url')}
              defaultValue={app?.icon_url}
              type="url"
              size="xs"
              label="Icon URL"
              icon={<ImageFill className="w-5 h-5 text-neutral-40" />}
              classNames={{
                input: 'bg-neutral rounded-lg',
              }}
              styles={(theme) => ({
                label: {
                  marginBottom: 8,
                  color: theme.colors.neutral[4],
                  fontWeight: 400,
                  fontSize: 16,
                },
              })}
            />

            <InputField
              label="Home page URL"
              {...appDataForm.getInputProps('website_url')}
              defaultValue={app?.website_url}
              size="sm"
              icon={<BoxFill className="w-6 h-6 text-neutral-20" />}
              classNames={{
                input: 'bg-neutral rounded-lg',
              }}
              styles={(theme) => ({
                label: {
                  marginBottom: 8,
                  color: theme.colors.neutral[4],
                  fontWeight: 400,
                  fontSize: 16,
                },
              })}
            />
            <InputField
              label="Destination URL"
              {...appDataForm.getInputProps('destination_url')}
              defaultValue={app?.website_url}
              size="sm"
              icon={<BoxFill className="w-6 h-6 text-neutral-20" />}
              classNames={{
                input: 'bg-neutral rounded-lg',
              }}
              styles={(theme) => ({
                label: {
                  marginBottom: 8,
                  color: theme.colors.neutral[4],
                  fontWeight: 400,
                  fontSize: 16,
                },
              })}
            />

            <Textarea
              label="IP address"
              {...appDataForm.getInputProps('allowed_ips')}
              defaultValue={app?.allowed_ips}
              size="sm"
              minRows={5}
              icon={<BoxFill className="w-6 h-6 text-neutral-20" />}
              classNames={{
                input: 'bg-neutral rounded-lg',
              }}
              styles={(theme) => ({
                label: {
                  marginBottom: 8,
                  color: theme.colors.neutral[4],
                  fontWeight: 400,
                  fontSize: 16,
                },
              })}
            />
          </Box>
          <Box className="flex justify-center">
            <AccessControl permissions={StaffPermissions.UPDATE_APPS}>
              <Button loading={useUpdateAppMutation.isLoading} disabled={!appDataForm.isDirty()} type="submit">
                Update information
              </Button>
            </AccessControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function AppTab() {
  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.apps.path} />;
  }

  return <AppTabRenderer id={id} />;
}
